import FullWidthTabs from '../FullWidthTabs';
import UsersTabContent from '../Tabs/UsersTabContent/UsersTabContent';
import StatementsTabContent from './StatementsTabContent/StatementsTabContent';
import ProfileTabContent from './ProfileTabContent/ProfileTabContent';
import { Statement } from '../../pages/ReconciliationAccount/controller';
import ReportsTabContent from './ReportsTabContent/ReportsTabContent';
import {
  ReconciliationAccountReport,
  ReconciliationReportDetails,
} from '../../models/ReconciliationReport';
import { EditReconciliationAccountParams } from '../../models/Resources';
import { User, UserRoleType } from '../../models/User';
import { AccountHeaders } from '../../view-models/ReconciliationAccountViewModel';

interface ReconciliationAccountProps {
  workspaceName: string;
  entityName: string;
  users: User[];
  bankStatements: Statement[];
  ledgerStatements: Statement[];
  reports: ReconciliationAccountReport[];
  updateParams: EditReconciliationAccountParams;
  userAvailableRoles: UserRoleType[];
  updateReconciliationAccountName: (name: string) => void;
  updateCounterpartyContactName: (counterpartyName: string) => void;
  updateCounterpartyContactEmail: (counterpartyEmail: string) => void;
  updateReconciliationAccount: (updateParams: Partial<EditReconciliationAccountParams>) => void;
  navigateToReport: (reportId: string) => void;
  deleteReconciliationAccount: () => Promise<void>;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
  fetchUsersDetails: () => void;
  editReconciliationReportDetails: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
  showDeleteReconciliationReportDialog: (reportId: string) => void;
  isReconciliationAccountReportLocked: (reportId: string) => boolean;
  balanceHeaders: () => AccountHeaders;
}

function ReconciliationAccount(props: ReconciliationAccountProps): JSX.Element {
  const {
    workspaceName,
    entityName,
    users,
    bankStatements,
    ledgerStatements,
    reports,
    updateParams,
    updateReconciliationAccount,
    updateReconciliationAccountName,
    updateCounterpartyContactName,
    updateCounterpartyContactEmail,
    navigateToReport,
    deleteReconciliationAccount,
    downloadAdjustmentsReport,
    userAvailableRoles,
    createUserWithEmailAndRoleId,
    downloadReconciliationReport,
    fetchUsersDetails,
    editReconciliationReportDetails,
    showDeleteReconciliationReportDialog,
    isReconciliationAccountReportLocked,
    balanceHeaders,
  } = props;
  return (
    <FullWidthTabs
      tabs={[
        {
          label: 'Reconciliation Reports',
          content: (
            <ReportsTabContent
              reports={reports}
              navigateToReport={navigateToReport}
              downloadAdjustmentsReport={downloadAdjustmentsReport}
              downloadReconciliationReport={downloadReconciliationReport}
              editReconciliationReportDetails={editReconciliationReportDetails}
              showDeleteReconciliationReportDialog={showDeleteReconciliationReportDialog}
              isReconciliationAccountReportLocked={isReconciliationAccountReportLocked}
              balanceHeaders={balanceHeaders}
            />
          ),
        },
        // {
        //   label: 'Statements',
        //   content: (
        //     <StatementsTabContent
        //       bankStatements={bankStatements}
        //       ledgerStatements={ledgerStatements}
        //     />
        //   ),
        // },
        {
          label: 'Profile',
          content: (
            <ProfileTabContent
              workspaceName={workspaceName}
              entityName={entityName}
              updateReconciliationAccountName={updateReconciliationAccountName}
              updateCounterpartyContactName={updateCounterpartyContactName}
              updateCounterpartyContactEmail={updateCounterpartyContactEmail}
              updateParams={updateParams}
              updateReconciliationAccount={updateReconciliationAccount}
              deleteReconciliationAccount={deleteReconciliationAccount}
            />
          ),
        },
        {
          label: 'Users',
          content: (
            <UsersTabContent
              users={users}
              userAvailableRoles={userAvailableRoles}
              createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
            />
          ),
          onTabOpen: fetchUsersDetails,
        },
      ]}
    />
  );
}
export default ReconciliationAccount;
