import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ReconciliationAccountPage from './page';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';

export type Statement = {
  filename: string;
  uploadDate: string;
  userUploaded: string;
  description: string;
  openingBalance: string;
  closingBalance: string;
};

export type Report = {
  reportName: string;
  label: string;
  comment: string;
  reconciliationPeriod: string;
  ledgerOpeningBalance: string;
  ledgerClosingBalance: string;
  bankOpeningBalance: string;
  bankClosingBalance: string;
  year: string;
  owner: string;
  status: 'draft' | 'locked';
};

const ledgerStatements: Statement[] = [
  {
    filename: 'Ledger Statement 1',
    uploadDate: '01/01/2021',
    userUploaded: 'User 1',
    description: 'Description 1',
    openingBalance: '1000',
    closingBalance: '2000',
  },
  {
    filename: 'Ledger Statement 2',
    uploadDate: '01/01/2021',
    userUploaded: 'User 1',
    description: 'Description 2',
    openingBalance: '1000',
    closingBalance: '2000',
  },
];

const bankStatements: Statement[] = [
  {
    filename: 'Bank Statement 1',
    uploadDate: '01/01/2021',
    userUploaded: 'User 1',
    description: 'Description 1',
    openingBalance: '1000',
    closingBalance: '2000',
  },
  {
    filename: 'Bank Statement 2',
    uploadDate: '01/01/2021',
    userUploaded: 'User 1',
    description: 'Description 2',
    openingBalance: '1000',
    closingBalance: '2000',
  },
];

const ReconciliationAccountController: React.FC = () => {
  const workspaceViewModel = useWorkspacesViewModel();
  const entityViewModel = useEntityViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  const navigate = useNavigate();
  useEffect(() => {
    if (!reconciliationAccountViewModel.hasReconciliationAccounts) {
      navigate('/dashboard');
    }
  }, [reconciliationAccountViewModel.reconciliationAccountsList]);

  const params = useParams<{
    workspaceId: string;
    entityId: string;
    reconciliationAccountId: string;
  }>();
  const { workspaceId, entityId, reconciliationAccountId } = params;
  if (!workspaceViewModel.selectedWorkspace && workspaceId)
    workspaceViewModel.selectWorkspace(workspaceId);
  if (!entityViewModel.entity && !entityViewModel.clearedEntity && entityId) {
    console.log('[ReconciliationAccountController] [Entity] selectEntity', entityId);
    entityViewModel.selectEntity(entityId);
  }
  if (
    !reconciliationAccountViewModel.reconciliationAccount &&
    !reconciliationAccountViewModel.clearedReconciliationAccount &&
    reconciliationAccountId
  )
    reconciliationAccountViewModel.selectReconciliationAccount(reconciliationAccountId);

  const navigateToReport = (reportId: string) => {
    navigate(`/reconciliation-report/${reportId}`);
  };
  const onStartNewReconciliationReport = () => {
    navigate('/create-reconciliation-report');
  };

  return (
    <ReconciliationAccountPage
      workspaceName={workspaceViewModel.selectedWorkspace?.data?.name || ''}
      entityName={entityViewModel.entity?.data?.name || ''}
      currentReconciliationAccount={reconciliationAccountViewModel.reconciliationAccount.data}
      createUserWithEmailAndRoleId={reconciliationAccountViewModel.createUserWithEmailAndRoleId}
      users={reconciliationAccountViewModel.users}
      userAvailableRoles={reconciliationAccountViewModel.availableUserRoles}
      ledgerStatements={ledgerStatements}
      bankStatements={bankStatements}
      reports={reconciliationAccountViewModel.reconciliationAccountReportsOrderedByCreatedAt}
      updateReconciliationAccountName={
        reconciliationAccountViewModel.updateReconciliationAccountName
      }
      updateCounterpartyContactName={reconciliationAccountViewModel.updateCounterpartyContactName}
      updateCounterpartyContactEmail={reconciliationAccountViewModel.updateCounterpartyContactEmail}
      updateReconciliationAccount={reconciliationAccountViewModel.updateReconciliationAccount}
      updateParams={reconciliationAccountViewModel.updateParams}
      onStartNewReconciliationReportClick={onStartNewReconciliationReport}
      status="success"
      navigateToReport={navigateToReport}
      deleteReconciliationAccount={reconciliationAccountViewModel.deleteReconciliationAccount}
      downloadAdjustmentsReport={reconciliationAccountViewModel.downloadAdjustmentsReport}
      downloadReconciliationReport={reconciliationAccountViewModel.downloadReconciliationReport}
      fetchUsersDetails={reconciliationAccountViewModel.fetchUsersDetails}
      editReconciliationReportDetails={
        reconciliationAccountViewModel.editReconciliationReportDetails
      }
      showDeleteReconciliationReportDialog={
        reconciliationAccountViewModel.showDeleteReconciliationReportDialog
      }
      isReconciliationAccountReportLocked={
        reconciliationAccountViewModel.isReconciliationAccountReportLocked
      }
      balanceHeaders={reconciliationAccountViewModel.balanceHeaders}
    />
  );
};

const RAC = observer(ReconciliationAccountController);

export { RAC as ReconciliationAccountControllerPage };
