import { Divider } from '@mui/material';
import { Table } from './Styles';
import Headers from './Headers/index';
import Body from './Body/index';
import {
  ReconciliationAccountReport,
  ReconciliationReportDetails,
} from '../../../models/ReconciliationReport';
import { AccountHeaders } from '../../../view-models/ReconciliationAccountViewModel';

interface ReportsTabContentProps {
  reports: ReconciliationAccountReport[];
  navigateToReport: (reportId: string) => void;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
  editReconciliationReportDetails: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
  showDeleteReconciliationReportDialog: (reportId: string) => void;
  isReconciliationAccountReportLocked: (reportId: string) => boolean;
  balanceHeaders: () => AccountHeaders;
}

function ReportsTabContent(props: ReportsTabContentProps): JSX.Element {
  const {
    reports,
    navigateToReport,
    downloadAdjustmentsReport,
    downloadReconciliationReport,
    editReconciliationReportDetails,
    showDeleteReconciliationReportDialog,
    isReconciliationAccountReportLocked,
    balanceHeaders,
  } = props;

  return (
    <Table
      sx={{
        width: {
          xs: '800px',
          lg: 'var(--page-max-width)',
        },
      }}
    >
      <Headers balanceHeaders={balanceHeaders} />
      <Divider />
      <Body
        reports={reports}
        navigateToReport={navigateToReport}
        downloadAdjustmentsReport={downloadAdjustmentsReport}
        downloadReconciliationReport={downloadReconciliationReport}
        editReconciliationReportDetails={editReconciliationReportDetails}
        showDeleteReconciliationReportDialog={showDeleteReconciliationReportDialog}
        isReconciliationAccountReportLocked={isReconciliationAccountReportLocked}
      />
    </Table>
  );
}

export default ReportsTabContent;
