/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import PageDetails from '../../components/PageDetails/Details';
import ReconciliationAccountComponent from '../../components/ReconciliationAccount/ReconciliationAccount';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { Statement } from './controller';
import {
  ReconciliationAccountReport,
  ReconciliationReportDetails,
} from '../../models/ReconciliationReport';
import { EditReconciliationAccountParams, ReconciliationAccount } from '../../models/Resources';
import { User, UserRoleType } from '../../models/User';
import { AccountHeaders } from '../../view-models/ReconciliationAccountViewModel';

interface ReconciliationAccountPageProps {
  workspaceName: string;
  entityName: string;
  users: User[];
  currentReconciliationAccount: ReconciliationAccount | null;
  bankStatements: Statement[];
  ledgerStatements: Statement[];
  reports: ReconciliationAccountReport[];
  updateParams: EditReconciliationAccountParams;
  userAvailableRoles: UserRoleType[];
  updateReconciliationAccountName: (name: string) => void;
  updateCounterpartyContactName: (counterpartyName: string) => void;
  updateCounterpartyContactEmail: (counterpartyEmail: string) => void;
  updateReconciliationAccount: (updateParams: Partial<EditReconciliationAccountParams>) => void;
  onStartNewReconciliationReportClick: () => void;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  navigateToReport: (reportId: string) => void;
  deleteReconciliationAccount: () => Promise<void>;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
  fetchUsersDetails: () => void;
  editReconciliationReportDetails: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
  showDeleteReconciliationReportDialog: (reportId: string) => void;
  isReconciliationAccountReportLocked: (reportId: string) => boolean;
  balanceHeaders: () => AccountHeaders;
}

export default function ReconciliationAccountPage(
  props: ReconciliationAccountPageProps
): JSX.Element {
  const {
    workspaceName,
    entityName,
    users,
    bankStatements,
    ledgerStatements,
    reports,
    updateParams,
    currentReconciliationAccount,
    updateReconciliationAccount,
    updateReconciliationAccountName,
    updateCounterpartyContactName,
    updateCounterpartyContactEmail,
    onStartNewReconciliationReportClick,
    status,
    errorMessage,
    navigateToReport,
    deleteReconciliationAccount,
    downloadAdjustmentsReport,
    userAvailableRoles,
    createUserWithEmailAndRoleId,
    downloadReconciliationReport,
    fetchUsersDetails,
    editReconciliationReportDetails,
    showDeleteReconciliationReportDialog,
    isReconciliationAccountReportLocked,
    balanceHeaders,
  } = props;

  return (
    <>
      <PageDetails
        header={
          currentReconciliationAccount
            ? currentReconciliationAccount.name
            : 'Reconciliation Account'
        }
        action={
          status === 'success' ? (
            <SimpleButton
              content="Start New Reconciliation Report"
              onClick={onStartNewReconciliationReportClick}
            />
          ) : undefined
          //  <SimpleButton icon={deleteIcon} content="Delete Reconciliation Account" /> : undefined
        }
      />
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && (
        <>
          {currentReconciliationAccount ? (
            <ReconciliationAccountComponent
              workspaceName={workspaceName}
              entityName={entityName}
              users={users}
              userAvailableRoles={userAvailableRoles}
              createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
              bankStatements={bankStatements}
              ledgerStatements={ledgerStatements}
              reports={reports}
              updateReconciliationAccountName={updateReconciliationAccountName}
              updateCounterpartyContactName={updateCounterpartyContactName}
              updateCounterpartyContactEmail={updateCounterpartyContactEmail}
              navigateToReport={navigateToReport}
              updateParams={updateParams}
              updateReconciliationAccount={updateReconciliationAccount}
              deleteReconciliationAccount={deleteReconciliationAccount}
              downloadAdjustmentsReport={downloadAdjustmentsReport}
              fetchUsersDetails={fetchUsersDetails}
              downloadReconciliationReport={downloadReconciliationReport}
              editReconciliationReportDetails={editReconciliationReportDetails}
              showDeleteReconciliationReportDialog={showDeleteReconciliationReportDialog}
              isReconciliationAccountReportLocked={isReconciliationAccountReportLocked}
              balanceHeaders={balanceHeaders}
            />
          ) : (
            <Loader />
          )}
        </>
      )}
    </>
  );
}
