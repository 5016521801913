import * as XLSX from 'xlsx';
import { TReconciliationStatus } from './models/CreateReconciliationReport';
import { DOCUMENT_TYPE } from './models/UploadFileData';
import { Entity, ReconciliationReport, ReconciliationAccount } from './models/Resources';
import {
  UnreconciledTransactionsBlock,
  ReconciledTransactionsBlock,
  ReconciliationAccountReport,
  ReconciledTransaction,
  MatchType,
} from './models/ReconciliationReport';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES, CONCRETE_TYPES } from './models/Document';
import {
  BanksSupported,
  CUSTOM_OPTIONS,
  LEDGER_ERP_OPTIONS,
} from './view-models/ConvertersAndTemplatesViewModel';

export interface SuggestedMatchProps {
  reconciliationReportId: string;
  className?: string;
  id: string;
  bankTransactions: {
    date: string;
    description: string;
    reference: string;
    debit?: string;
    credit?: string;
  }[];
  ledgerTransactions: {
    date: string;
    description: string;
    reference: string;
    debit?: string;
    credit?: string;
  }[];
  timeout?: number;
  acceptSuggestedMatch: (reconciliationReportId: string, suggestedMatchId: string) => void;
  rejectSuggestedMatch: (reconciliationReportId: string, suggestedMatchId: string) => void;
}

export interface SuggestedMatchesProps {
  reconciliationReportId: string;
  suggestedMatches: {
    id: string;
    bankStatementTransactions: {
      date: string;
      description: string;
      reference: string;
      debit: string;
      credit: string;
    }[];
    ledgerTransactions: {
      date: string;
      description: string;
      reference: string;
      debit: string;
      credit: string;
    }[];
  }[];
  timeout?: number;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  acceptSuggestedMatch: (reconciliationReportId: string, suggestedMatchId: string) => void;
  rejectSuggestedMatch: (reconciliationReportId: string, suggestedMatchId: string) => void;
  acceptSuggestions: (from: number, to: number) => void;
  rejectSuggestions: (from: number, to: number) => void;
}

export interface VisibleSuggestedMatchesProps extends SuggestedMatchesProps {
  rowsPerPage: number;
  page: number;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  acceptSuggestions: (from: number, to: number) => void;
  rejectSuggestions: (from: number, to: number) => void;
}

export interface MatchProps {
  className?: string;
  bankTransactions: {
    date: string;
    description: string;
    reference: string;
    debit?: string;
    credit?: string;
  }[];
  ledgerTransactions: {
    date: string;
    description: string;
    reference: string;
    debit?: string;
    credit?: string;
  }[];
}

export interface TransactionProps {
  className?: string;
  date: string;
  description: string;
  reference: string;
  debit?: string;
  credit?: string;
}

export interface MuiButtonProps {
  type?: 'accept' | 'reject' | 'simple';
  content: string;
  color: string;
  iconLeft?: string;
  iconRight?: string;
}

export interface SMLayoutProps {
  children?: React.ReactNode;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  acceptSuggestions: (from: number, to: number) => void;
  rejectSuggestions: (from: number, to: number) => void;
  from: number;
  to: number;
}

export type STATUS_STATES = 'idle' | 'uploaded' | 'uploading' | 'error';

export interface UploadFileProps {
  type: DOCUMENT_TYPE;
  concreteType: CONCRETE_TYPES;
  disableUpload: boolean;
  status: STATUS_STATES;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => void;
  setStatus: (status: STATUS_STATES) => void;
}

export interface AcceptButtonProps {
  // onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  onClick: (reconciliationReportId: string, suggestedMatchId: string) => void;
  reconciliationReportId: string;
  suggestedMatchId: string;
}
export interface RejectButtonProps {
  // onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  onClick: (reconciliationReportId: string, suggestedMatchId: string) => void;
  reconciliationReportId: string;
  suggestedMatchId: string;
}
// maybe create different for entity and reconciliation account
export interface FilledButtonProps {
  disabled?: boolean;
  content: string;
  icon?: string;
  onClick?: () => void;
  bank?: boolean;
  // onClick?: (params: CreateWorkspaceParams) => void;
}

export interface ConvertersAndTemplatesPageProps {
  pdfUpladFileStatus: STATUS_STATES;
  customUploadFileStatus: STATUS_STATES;
  bankUploadFileStatus: STATUS_STATES;
  ledgerUploadFileStatus: STATUS_STATES;
  customOptions: CUSTOM_OPTIONS[];
  selectedCustom: CUSTOM_OPTIONS;
  bankTransactionTypes: BanksSupported[];
  handleBankConvert: (sheet: XLSX.WorkSheet, fileName: string, bankType: string) => void;
  errorMessage?: string;
  status: 'loading' | 'success' | 'error';
  selectedBank: BanksSupported;
  erpOptions: LEDGER_ERP_OPTIONS[];
  selectedERP: LEDGER_ERP_OPTIONS;
  setSelectedBank: (bank: BanksSupported) => void;
  uploadedBankFile: File | null;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  handlePdfFileUpload: (file: File) => void;
  handleBankFileUpload: (file: File) => void;
  downloadLedgerTemplate: () => void;
  downloadBankTemplate: () => void;
  showCreateReconciliationAccountDialog: () => void;
  convertPdfDocument: () => void;
  convertLedgerDocument: () => void;
  handleLedgerFileUpload: (file: File) => void;
  setSelectedERP: (value: LEDGER_ERP_OPTIONS) => void;
  handleCustomFileUpload: (file: File) => void;
  convertCustomDocument: () => void;
  setSelectedCustom: (value: CUSTOM_OPTIONS) => void;
  setLedgerUploadFileStatus: (value: STATUS_STATES) => void;
  setBankUploadFileStatus: (value: STATUS_STATES) => void;
  setCustomUploadFileStatus: (value: STATUS_STATES) => void;
  setPdfUploadFileStatus: (value: STATUS_STATES) => void;
}

export interface SimpleButtonProps {
  icon?: string;
  content: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export interface FixedBottomButtonProps {
  content: string;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  drawerOpen: boolean;
}

export interface SimpleBlockProps {
  children: React.ReactNode;
}

export interface InnerBlockProps {
  key?: number;
  children: React.ReactNode;
}

export interface InnerBlockHeaderProps {
  dateHeader?: string;
  descriptionHeader?: string;
  referenceHeader?: string;
  amountHeader: string;
}

export interface InnerBlocksProps extends InnerBlockHeaderProps {
  blocks: {
    date: string;
    description: string;
    reference: string;
    amount: string;
  }[];
}

export interface UnreconciledTransactionsProps extends InnerBlocksProps, InnerBlockHeaderProps {
  title: string;
  type?: 'ledger' | 'bank';
  total: string;
  adjustedBalance: string;
}

export interface ReconciledTransactionBlockProps {
  id: string;
  bankStatementTransactions: ReconciledTransaction[];
  ledgerTransactions: ReconciledTransaction[];
  type: MatchType;
  unreconcileMatch: (matchId: string) => void;
}

export interface ReconciledTransactionBlocksProps {
  reconciledTransactionsBlocks:
    | {
        id: string;
        bankStatementTransactions: ReconciledTransaction[];
        ledgerTransactions: ReconciledTransaction[];
        type: MatchType;
      }[]
    | null;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  unreconcileMatch: (matchId: string) => void;
  bankStatementTransactionsPercentage: number | null;
}

export interface UnreconciledTransactionsBlocksProps {
  ledgerEndBalance: string;
  bankClosingBalance: string;
  reconciliationDifference: string;
  unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] | null;
  ledgerClosingBalance: string;
  externalClosingBalanceName: string;
  externalStatementTransactionsPercentage: number | null;
  accountType?: string;
}

export interface IError {
  message: string;
}

// export interface ReconciledTransactionBlocks {
//   blocks: ReconciledTransactionBlockProps[];
// }

export interface IPageDetailsProps {
  header: string;
  subheader?: string;
  action?: JSX.Element;
}

// TODO these should be moved to the pages they are used in
// TODO use enums for status
// Pages
export interface CreateReconciliationReportPageProps {
  // reconciliationAccounts: EntityReconciliationAccountSlim[];
  ledgerNotInOurTemplateUploadFileStatus: STATUS_STATES;
  bankNotInOurTemplateUploadFileStatus: STATUS_STATES;
  ledgerUploadFileStatus: STATUS_STATES;
  bankUploadFileStatus: STATUS_STATES;
  reconciliationReports?: ReconciliationReport[];
  status: TReconciliationStatus;
  errorMessage?: string;
  progress: number;
  message: string;
  id: string;
  total: number;
  totalReconciled: number;
  reconciliationReportCreated: boolean;
  reconciliationReportStarted: boolean;
  bankDocumentId: string;
  bankDocumentIdNotInOurTemplate: string;
  ledgerDocumentId: string;
  ledgerDocumentIdNotInOurTemplate: string;
  previousReconciliationReports: ReconciliationAccountReport[];
  filesUploaded: boolean;
  ledgerDocumentUploaded: boolean;
  ledgerDocumentNotInOurTemplateUploaded: boolean;
  reconciliationReportPeriodSet: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  bankDocumentUploaded: boolean;
  bankDocumentNotInOurTemplateUploaded: boolean;
  reconciliationAccount: string;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
  createReconciliationReport: (params: {
    externalDocumentId: string;
    ledgerDocumentId: string;
    ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
    externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
    previousReconciliationReportId?: string;
  }) => void;
  createReconciliationReportWithCompensatingLedgerTransaction: (params: {
    externalDocumentId: string;
    ledgerDocumentId: string;
    ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
    externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
    previousReconciliationReportId?: string;
  }) => void;
  uploadFileNotInOurTemplate: (
    file: File,
    type: DOCUMENT_TYPE,
    concreteType: CONCRETE_TYPES
  ) => Promise<void>;
  setBankUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerUploadFileStatus: (value: STATUS_STATES) => void;
  setBankNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
}

export interface SuggestedMatchesPageProps {
  currentEntity: Entity | null;
  currentReconciliationAccount: ReconciliationAccount | null;
  currentReconciliationReport: string;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  timeout?: number;
  suggestedMatches: {
    id: string;
    bankStatementTransactions: {
      date: string;
      description: string;
      reference: string;
      debit: string;
      credit: string;
    }[];
    ledgerTransactions: {
      date: string;
      description: string;
      reference: string;
      debit: string;
      credit: string;
    }[];
  }[];
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  acceptSuggestedMatch: (reconciliationReportId: string, suggestedMatchId: string) => void;
  rejectSuggestedMatch: (reconciliationReportId: string, suggestedMatchId: string) => void;
  acceptSuggestions: (from: number, to: number) => void;
  rejectSuggestions: (from: number, to: number) => void;
}

export interface ReconciliationReportPageProps {
  currentEntity: Entity | null;
  currentReconciliationAccount: ReconciliationAccount | null;
  currentReconciliationReportName: string;
  unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] | null;
  reconciledTransactionsBlocks: ReconciledTransactionsBlock[] | null;
  ledgerEndBalance: string;
  bankClosingBalance: string;
  reconciliationDifference: string;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  isReconciliationReportLocked: boolean;
  ledgerClosingBalance: string;
  externalClosingBalanceName: string;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  handleDownloadXLSXButtonClick: () => void | Promise<void>;
  navigateToManualReconciliation: () => void;
  unreconcileMatch: (matchId: string) => void;
  showLockReconciliationReportDialog: () => void;
  bankStatementTransactionsPercentage: number | null;
}

export type ResponseCallback<T> = (response: T) => void;

export type Error = {
  code: string;
  message: string;
};

export type RepositoryResponse<T> = {
  ok: T | null;
  error: Error | null;
};

export enum AsyncStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  CACHED = 'cached',
  ERROR = 'error',
}

export type DecodedToken = {
  sub: string;
  exp: number;
  iat: number;
};

export enum CacheErrors {
  EMPTY_CACHE = 'Empty cache',
}

interface IdleState<T> {
  data: T;
  status: AsyncStatus.IDLE;
  fetchedAt: 0;
  error: null;
}

interface PendingState<T, E> {
  data: T | null;
  status: AsyncStatus.PENDING;
  fetchedAt: number;
  error: E | null;
}

interface CachedState<T> {
  data: T;
  status: AsyncStatus.CACHED;
  fetchedAt: number;
  error: null;
}

interface EmptyCacheState {
  data: null;
  status: AsyncStatus.CACHED;
  fetchedAt: number;
  error: CacheErrors | null;
}

interface OkState<T> {
  data: T;
  status: AsyncStatus.SUCCESS;
  fetchedAt: number;
  error: null;
}

interface ErrorState<E> {
  data: null;
  status: AsyncStatus.ERROR;
  fetchedAt: number;
  error: E;
}

export type State<T, E> =
  | IdleState<T>
  | PendingState<T, E>
  | CachedState<T>
  | EmptyCacheState
  | OkState<T>
  | ErrorState<E>;

export const initState = <T, E>(initData: T): State<T, E> => ({
  data: initData,
  status: AsyncStatus.IDLE,
  fetchedAt: 0,
  error: null,
});

export const fetchedState = <T>(data: T): State<T, null> => ({
  data,
  status: AsyncStatus.SUCCESS,
  fetchedAt: Date.now(),
  error: null,
});

export const cachedState = <T>(data: T, fetchedAt: number): State<T, null> => ({
  data,
  status: AsyncStatus.CACHED,
  fetchedAt,
  error: null,
});

export const errorState = <E>(error: E): State<null, E> => ({
  data: null,
  status: AsyncStatus.ERROR,
  fetchedAt: Date.now(),
  error,
});
