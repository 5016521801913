/* eslint-disable no-continue */
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { createContext, useContext, useState } from 'react';
import { writeFileXLSX } from 'xlsx';
import {
  ReconciliationReport,
  ReconciliationTransactionStatuses,
  UnreconciledTransactionsBlock,
  ReconciledTransactionsBlock,
  AMOUNT_TYPE,
  AMOUNT_TYPE_HEADER,
  Transaction,
  ReconciliationAccountReport,
  ReconciledTransaction,
  RECONCILIATION_MATCH_TYPES,
} from '../models/ReconciliationReport';
import { fixToThousandSeparator, fixToTwoDecimals } from '../utils/number';
import { useReconciliationReportRepository } from '../context/DI';
import {
  CreateReconciliationReportData,
  CreateReconciliationReportParams,
  RECONCILIATION_REPORT_STATUSES,
  ReconciliationReportPeriod,
  ReconciliationStatuses,
  TReconciliationStatus,
} from '../models/CreateReconciliationReport';
import {
  BaseError,
  CreateLedgerTransactionError,
  CreateLedgerTransactionsError,
  DeleteLedgerTransactionError,
  EditLedgerTransactionError,
  IReconciliationReportRepository,
  LockReconciliationError,
  ManualReconciliationError,
  ReconciliationReportFetchError,
  UnReconcileMatchError,
} from '../infra/repositories/reconciliation-report';
import { AsyncStatus, State, fetchedState, initState } from '../types';
import { DocumentUploadedPayload, EventBus, Events } from '../Events';
import { BitloopsError, ErrorCodes } from '../Errors';
import { RECONCILIATION_ACCOUNT_TYPES, ReconciliationAccount } from '../models/Resources';
import { ReconciliationAccountFetchError } from '../infra/repositories/resources';
import {
  CreateLedgerTransaction,
  CreateLedgerTransactionParams,
  CreateLedgerTransactionsParams,
  DeleteLedgerTransactionParams,
  EditLedgerTransactionParams,
  FilterOptions,
  MANUAL_RECONCILABLE_STATUSES,
  ManualLedgerTransactionParams,
  type ManualReconcilableStatus,
  type ManualTransaction,
  type SelectedTransactions,
} from '../models/ManualReconciliation';
import ReconciliationReportExcelFile from '../utils/report-to-excel';
import DateUtils from '../utils/dates';
import { LEDGER_DOCUMENT_TYPES, EXTERNAL_DOCUMENT_TYPES } from '../models/Document';
import ManualTransactionFilter from '../helpers/ManualTransactionFilter';
import ManualTransactionHelper from '../helpers/ManualTransaction';
import { DOCUMENT_TYPE } from '../models/UploadFileData';

export const CREDITS_NOT_PRESENT = 'Credits not present in';
export const DEBITS_NOT_PRESENT = 'Debits not present in';
export const LEDGER_CREDITS_HEADER = `Ledger ${CREDITS_NOT_PRESENT} `;
export const LEDGER_DEBITS_HEADER = `Ledger ${DEBITS_NOT_PRESENT} `;
export const EXTERNAL_CREDITS_HEADER = `${CREDITS_NOT_PRESENT} Ledger`;
export const EXTERNAL_DEBITS_HEADER = `${DEBITS_NOT_PRESENT} Ledger`;
export const NOT_APPLICABLE = 'Not Applicable';
const INVALID_DATE = 'Invalid Date';

interface IReconciliationReportViewModel {
  reconciliationReportId: string | null;
  status: TReconciliationStatus;
  created: boolean;
  progress: number;
  message: string;
  total: number;
  totalReconciled: number;

  reconciledTransactions: ReconciledTransactionsBlock[] | null;
  unreconciledTransactions: UnreconciledTransactionsBlock[] | null;
  unreconciledLedgerTransactions: ManualTransaction[];
  unreconciledBankTransactions: ManualTransaction[];
  ledgerEndBalance: string;
  bankClosingBalance: string;
  reconciliationDifference: string;
  isNewLedgerTransactionDialogVisible: boolean;
  ledgerTransactionDate: string;
  ledgerTransactionDescription: string;
  ledgerTransactionReference: string;
  ledgerTransactionAmount: string;
  ledgerTransactionFinancialTransactionId: string;
  ledgerTransactionType: string;
  reconciliationPeriodStartDate: string;
  reconciliationPeriodEndDate: string;
  getReconciliationReport(
    reconciliationReportId: string
  ): State<ReconciliationReport | null, ReconciliationReportFetchError>;
  createReconciliationReport: (params: {
    externalDocumentId: string;
    ledgerDocumentId: string;
    ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
    externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
    previousReconciliationReportId?: string;
  }) => void;
  handleDownloadXLSXButtonClick: () => void;
  showCreateLedgerTransactionDialog: () => void;
  setReconciliationReportId: (id: string) => void;
  setLedgerTransactionDate: (date: string) => void;
  setLedgerTransactionDescription: (description: string) => void;
  setLedgerTransactionReference: (reference: string) => void;
  setLedgerTransactionAmount: (amount: string) => void;
  setLedgerTransactionFinancialTransactionId: (id: string) => void;
  setLedgerTransactionType: (type: string) => void;
  setReconciliationPeriodStartDate: (date: string) => void;
  setReconciliationPeriodEndDate: (date: string) => void;
}

class ReconciliationReportViewModel implements IReconciliationReportViewModel {
  reconciliationReportId: string | null = null;

  private _reconciliationAccount: State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  > = initState(null);

  previousReconciliationReportId: string | null = null;

  private _reconciliationReport: State<
    ReconciliationReport | null,
    ReconciliationReportFetchError
  > = initState(null);

  private _ledgerDebitIncrease: boolean | null = null;

  private _bankDebitIncrease: boolean | null = null;

  private _bankOpeningBalance: number | null = null;

  private _ledgerOpeningBalance: number | null = null;

  private _isBankDateDropdownOpen = false;

  private _isBankDateAscending = true;

  private _isBankCreditDropdownOpen = false;

  private _isBankCreditAscending = true;

  private _isBankDebitDropdownOpen = false;

  private _isBankDebitAscending = true;

  private _isLedgerDateDropdownOpen = false;

  private _isLedgerDateAscending = true;

  private _isLedgerCreditDropdownOpen = false;

  private _isLedgerCreditAscending = true;

  private _isLedgerDebitDropdownOpen = false;

  private _isLedgerDebitAscending = true;

  private _showCreateLedgerTransactionDialog = false;

  private _showLockReconciliationReportDialog = false;

  private _showBankFilterPopUpDialog = false;

  private _showLedgerFilterPopUpDialog = false;

  status: TReconciliationStatus = ReconciliationStatuses.IDLE;

  total = 0;

  totalReconciled = 0;

  message = '';

  private _bankQuickFilter = '';

  private _ledgerQuickFilter = '';

  private _bankClosingBalance = '';

  private _reconciliationDifference = '';

  private _reconciliationAccountReports: State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  > = initState([]);

  private _unreconciledBankTransactions: ManualTransaction[] = [];

  private _unreconciledLedgerTransactions: ManualTransaction[] = [];

  private _manualReconcilableStatus: ManualReconcilableStatus = MANUAL_RECONCILABLE_STATUSES.IDLE;

  private _manualReconciliationDifference = '0.00';

  private _manualReconciliationDifferenceNumber = 0;

  private _bankTransactionsAreManuallyReconciled = false;

  private _ledgerTransactionsAreManuallyReconciled = false;

  private _showNewLedgerTransactionDialog: boolean = false;

  private _showEditLedgerTransactionDialog: boolean = false;

  private _draftLedgerTransaction: ManualLedgerTransactionParams =
    ReconciliationReportViewModel.initializeManualTransactionParams();

  private ledgerDocumentType: LEDGER_DOCUMENT_TYPES = LEDGER_DOCUMENT_TYPES.LEDGER;

  private externalDocumentType: EXTERNAL_DOCUMENT_TYPES = EXTERNAL_DOCUMENT_TYPES.BANK;

  private _bankFilterOptionsApplied: boolean = false;

  private _ledgerFilterOptionsApplied: boolean = false;

  private _bankFilterOptions: FilterOptions =
    ReconciliationReportViewModel.initializeFilterOptions();

  private _ledgerFilterOptions: FilterOptions =
    ReconciliationReportViewModel.initializeFilterOptions();

  @observable
  ledgerTransactionCreationStatus: AsyncStatus = AsyncStatus.IDLE;

  private _editLedgerTransactionParams: ManualLedgerTransactionParams =
    ReconciliationReportViewModel.initializeManualTransactionParams();

  private _reconciliationReportPeriod: ReconciliationReportPeriod = {
    startDate: INVALID_DATE,
    endDate: INVALID_DATE,
  };

  private _selectedTransactions: SelectedTransactions = {
    bankTransactions: [],
    ledgerTransactions: [],
  };

  private _autoGeneratedLedgerTransactions: CreateLedgerTransaction[] = [];

  private _datasetANetSum = 0;

  private _datasetBNetSum = 0;

  @observable
  ledgerTransactionEditStatus: AsyncStatus = AsyncStatus.IDLE;

  constructor(private reconciliationReportRepository: IReconciliationReportRepository) {
    makeAutoObservable(this);
    EventBus.subscribe(Events.RECONCILIATION_REPORT_FETCH, this.reconciliationReportFetchHandler);
    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_REPORTS_FETCH,
      this.reconciliationAccountReportsFetchHandler
    );
    EventBus.subscribe(Events.MANUAL_RECONCILIATION, this.manuallyReconciledHandler);
    EventBus.subscribe(Events.LOCK_RECONCILIATION_REPORT, this.lockReconciliationReportHandler);
    EventBus.subscribe(Events.UN_RECONCILE_MATCH, this.unReconcileMatchHandler);
    EventBus.subscribe(Events.CREATE_LEDGER_TRANSACTION, this.createLedgerTransactionHandler);
    EventBus.subscribe(Events.EDIT_LEDGER_TRANSACTION, this.editLedgerTransactionHandler);
    EventBus.subscribe(Events.DELETE_LEDGER_TRANSACTION, this.deleteLedgerTransactionHandler);
    EventBus.subscribe(Events.CREATE_LEDGER_TRANSACTIONS, this.createLedgerTransactionsHandler);
    EventBus.subscribe(Events.DOCUMENT_UPLOADED, this.documentUploadedHandler);
    EventBus.subscribe(Events.BANK_OB_UPLOAD_STARTED, this.bankOBUploadStartedHandler);
    EventBus.subscribe(Events.LEDGER_OB_UPLOAD_STARTED, this.ledgerOBUploadStartedHandler);

    this._reconciliationAccountReports = {
      ...this._reconciliationAccountReports,
      status: AsyncStatus.PENDING,
    };
  }

  get ledgerOpeningBalance(): number | null {
    return this._ledgerOpeningBalance;
  }

  get bankOpeningBalance(): number | null {
    return this._bankOpeningBalance;
  }

  get isReconciliationReportLocked(): boolean {
    return this._reconciliationReport.data
      ? this._reconciliationReport.data.status === RECONCILIATION_REPORT_STATUSES.LOCKED
      : false;
  }

  get reconciliationPeriodStartDate(): string {
    const previousReconcilationReport = this.getPreviousReconciliationReport();
    if (previousReconcilationReport) {
      const endDate = previousReconcilationReport.reconciliationPeriod.endingDate;

      const nextDayDate = DateUtils.GetNextDayDateString(endDate);
      this.setReconciliationPeriodStartDate(nextDayDate);
      return nextDayDate;
    }
    return this._reconciliationReportPeriod.startDate;
  }

  get reconciliationPeriodEndDate(): string {
    return this._reconciliationReportPeriod.endDate;
  }

  get bankFilterFromDate(): string {
    return this._bankFilterOptions.date.from;
  }

  get bankFilterToDate(): string {
    return this._bankFilterOptions.date.to;
  }

  get ledgerFilterFromDate(): string {
    return this._ledgerFilterOptions.date.from;
  }

  get ledgerFilterToDate(): string {
    return this._ledgerFilterOptions.date.to;
  }

  get reconciliationReportName(): string {
    return this._reconciliationReport.data ? this._reconciliationReport.data.name : '';
  }

  get areEditLedgerTransactionRequiredFieldsSet() {
    return (
      this._editLedgerTransactionParams.date !== INVALID_DATE &&
      this._editLedgerTransactionParams.description !== '' &&
      this._editLedgerTransactionParams.transactionType !== '' &&
      this._editLedgerTransactionParams.amount !== '0'
    );
  }

  get reconciliationReportPeriodSet() {
    return (
      this._reconciliationReportPeriod.startDate !== INVALID_DATE &&
      this._reconciliationReportPeriod.endDate !== INVALID_DATE
    );
  }

  get isEditLedgerTransactionDialogVisible(): boolean {
    return this._showEditLedgerTransactionDialog;
  }

  get created(): boolean {
    return (
      this.status === ReconciliationStatuses.SUCCESS &&
      this.reconciliationReportId !== null &&
      this.reconciliationReportId !== ''
    );
  }

  get progress(): number {
    if (this.totalReconciled > 0) {
      return Math.round((this.totalReconciled / this.total) * 100) * 100;
    }
    return 0;
  }

  get editLedgerTransactionParams(): ManualLedgerTransactionParams {
    return this._editLedgerTransactionParams;
  }

  get isCreateLedgerTransactionDialogVisible(): boolean {
    return this._showCreateLedgerTransactionDialog;
  }

  get isLockReconciliationReportDialogVisible(): boolean {
    return this._showLockReconciliationReportDialog;
  }

  get reconciliationAccountReports(): State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  > {
    return this._reconciliationAccountReports;
  }

  get previousReconciliationReportsOrderedByCreatedAt(): ReconciliationAccountReport[] {
    if (this._reconciliationAccountReports.data) {
      const lockedReports: ReconciliationAccountReport[] = [];
      this._reconciliationAccountReports.data.forEach((report) => {
        if (report.status === RECONCILIATION_REPORT_STATUSES.LOCKED) {
          lockedReports.push(report);
        }
      });
      return lockedReports.sort((a, b) => b.createdAt - a.createdAt);
    }
    return [];
  }

  get isBankDateAscending(): boolean {
    return this._isBankDateAscending;
  }

  get isBankDebitAscending(): boolean {
    return this._isBankDebitAscending;
  }

  get isBankCreditAscending(): boolean {
    return this._isBankCreditAscending;
  }

  get isLedgerDateAscending(): boolean {
    return this._isLedgerDateAscending;
  }

  get isLedgerDebitAscending(): boolean {
    return this._isLedgerDebitAscending;
  }

  get isLedgerCreditAscending(): boolean {
    return this._isLedgerCreditAscending;
  }

  get searchBankFilterOptions(): string {
    return this._bankFilterOptions.search;
  }

  get searchLedgerFilterOptions(): string {
    return this._ledgerFilterOptions.search;
  }

  get fromAmountBankFilterOptions(): number | null {
    return this._bankFilterOptions.amount.from;
  }

  get toAmountBankFilterOptions(): number | null {
    return this._bankFilterOptions.amount.to;
  }

  get fromAmountLedgerFilterOptions(): number | null {
    return this._ledgerFilterOptions.amount.from;
  }

  get toAmountLedgerFilterOptions(): number | null {
    return this._ledgerFilterOptions.amount.to;
  }

  get bankQuickFilter(): string {
    return this._bankQuickFilter;
  }

  set datasetANetSum(value: number) {
    this._datasetANetSum = value;
  }

  get datasetANetSum(): number {
    return this._datasetANetSum;
  }

  set datasetBNetSum(value: number) {
    this._datasetBNetSum = value;
  }

  get datasetBNetSum(): number {
    return this._datasetBNetSum;
  }

  get ledgerQuickFilter(): string {
    return this._ledgerQuickFilter;
  }

  applyLedgerDebitIncrease = (): void => {
    this._ledgerDebitIncrease = true;
    EventBus.emit(Events.LEDGER_DEBIT_INCREASED, fetchedState(null));
  };

  applyLedgerDebitDecrease = (): void => {
    this._ledgerDebitIncrease = false;
    EventBus.emit(Events.LEDGER_DEBIT_DECREASED, fetchedState(null));
  };

  applyBankDebitIncrease = (): void => {
    this._bankDebitIncrease = true;
    EventBus.emit(Events.BANK_DEBIT_INCREASED, fetchedState(null));
  };

  applyBankDebitDecrease = (): void => {
    this._bankDebitIncrease = false;
    EventBus.emit(Events.BANK_DEBIT_DECREASED, fetchedState(null));
  };

  shouldDisableStartDateFromDatePicker = (): boolean => {
    if (this.previousReconciliationReportId) {
      return true;
    }
    return false;
  };

  getPreviousReconciliationReport() {
    if (!this._reconciliationAccountReports.data) return null;
    return this._reconciliationAccountReports.data?.find(
      (report) => report?.id === this.previousReconciliationReportId
    );
  }

  @action
  setPreviousReconciliationReport = (value: string | null) => {
    this.previousReconciliationReportId = value;
  };

  @action
  showCreateLedgerTransactionDialog = () => {
    this._showCreateLedgerTransactionDialog = true;
  };

  @action
  hideCreateLedgerTransactionDialog = (): void => {
    if (this._showCreateLedgerTransactionDialog) {
      this._showCreateLedgerTransactionDialog = false;
    }
  };

  @action
  showLockReconciliationReportDialog = () => {
    this._showLockReconciliationReportDialog = true;
  };

  @action
  hideLockReconciliationReportDialog = (): void => {
    if (this._showLockReconciliationReportDialog) {
      this._showLockReconciliationReportDialog = false;
    }
  };

  @action
  reconciliationReportFetchHandler = (
    repoResponse: State<ReconciliationReport | null, ReconciliationReportFetchError>
  ) => {
    if (repoResponse.data) {
      this._reconciliationReport = repoResponse;
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  private fixTransactionAmountsAndDate = (transactions: Transaction[]): ReconciledTransaction[] => {
    const fixedTransactions: ReconciledTransaction[] = [];
    transactions.forEach((transaction) => {
      fixedTransactions.push({
        date: DateUtils.FormatYearToYY(transaction.date),
        description: transaction.description,
        reference: transaction.reference,
        debit: this.fixToThousandSeparator(transaction.debit),
        credit: this.fixToThousandSeparator(transaction.credit),
        financialTransactionId: transaction.financialTransactionId,
      });
    });
    return fixedTransactions;
  };

  private fixManualTransactionAmountsAndDate = (transaction: Transaction): ManualTransaction => {
    const fixedTransactions: ManualTransaction = {
      date: DateUtils.FormatYearToYY(transaction.date),
      description: transaction.description,
      reference: transaction.reference,
      status: transaction.status,
      rowId: transaction.rowId,
      financialTransactionId: transaction.financialTransactionId,
      debit: this.fixToThousandSeparator(transaction.debit),
      credit: this.fixToThousandSeparator(transaction.credit),
      debitNumber: transaction.debit,
      creditNumber: transaction.credit,
      selected: false,
    };
    return fixedTransactions;
  };

  private fixToThousandSeparator = (amount: number): string => {
    const fixedTwoDecimalsAmount = fixToTwoDecimals(amount);
    const fixedThousandAmount = fixToThousandSeparator(fixedTwoDecimalsAmount);
    return fixedThousandAmount;
  };

  @action
  createReconciliationReport = async (params: {
    externalDocumentId: string;
    ledgerDocumentId: string;
    ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
    externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  }): Promise<void> => {
    try {
      const { externalDocumentId, ledgerDocumentId, ledgerDocumentType, externalDocumentType } =
        params;
      const { previousReconciliationReportId } = this;
      console.log(
        'createReconciliationReport this._reconciliationReportPeriod',
        this._reconciliationReportPeriod
      );

      const createReconciliationReportParams: CreateReconciliationReportParams = {
        externalDocumentId,
        ledgerDocumentId,
        period: this._reconciliationReportPeriod,
        shouldCreateCompensatingTransaction: false,
        ledgerDocumentType,
        externalDocumentType,
      };

      this.ledgerDocumentType = ledgerDocumentType;
      this.externalDocumentType = externalDocumentType;
      if (previousReconciliationReportId)
        createReconciliationReportParams.previousReconciliationReportId =
          previousReconciliationReportId;
      if (this._ledgerOpeningBalance !== null) {
        createReconciliationReportParams.ledgerOB = this._ledgerOpeningBalance;
      }
      if (this._bankOpeningBalance !== null) {
        createReconciliationReportParams.externalOB = this._bankOpeningBalance;
      }
      if (this._ledgerDebitIncrease !== null) {
        createReconciliationReportParams.ledgerDebitIncrease = this._ledgerDebitIncrease;
      }
      if (this._bankDebitIncrease !== null) {
        createReconciliationReportParams.bankDebitIncrease = this._bankDebitIncrease;
      }
      this.status = ReconciliationStatuses.LOADING;
      await this.reconciliationReportRepository.createReconciliationReport(
        createReconciliationReportParams,
        (data: CreateReconciliationReportData) => this.onReceivedReconciliationData(data),
        () => this.onReconciliationReportCompleted(),
        (error: any) => {
          if (this.isMismatchLedgerError(error.code)) {
            this._showCreateLedgerTransactionDialog = true;
          } else {
            const err: BitloopsError = error as BitloopsError;
            EventBus.emit(Events.ERROR, err);
            this.status = ReconciliationStatuses.ERROR;
          }
        }
      );
    } catch (error: any) {
      this.status = ReconciliationStatuses.ERROR;
      console.error(error);
      console.error('createReconciliationReport catch', error);
    }
  };

  @action
  reconciliationAccountReportsFetchHandler = (
    repoResponse: State<ReconciliationAccountReport[], ReconciliationAccountFetchError>
  ) => {
    console.log('reconciliationAccountReportsFetchHandler', repoResponse);
    if (repoResponse.data) {
      this._reconciliationAccountReports = repoResponse;
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  fetchReconciliationAccountReports = (): void => {
    try {
      const repoResponse =
        this.reconciliationReportRepository.getReconciliationReportsFromCurrentAccountId();
      const { status, data } = repoResponse;
      if (status === AsyncStatus.SUCCESS) this._reconciliationAccountReports = fetchedState(data);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  createReconciliationReportWithComplementaryLedgerTransaction = async (params: {
    externalDocumentId: string;
    ledgerDocumentId: string;
    ledgerDocumentType?: LEDGER_DOCUMENT_TYPES;
    externalDocumentType?: EXTERNAL_DOCUMENT_TYPES;
  }): Promise<void> => {
    this.hideCreateLedgerTransactionDialog();
    console.log('createReconciliationReportWithComplementaryLedgerTransaction', params);

    try {
      const createParams: CreateReconciliationReportParams = {
        externalDocumentId: params.externalDocumentId,
        ledgerDocumentId: params.ledgerDocumentId,
        period: this._reconciliationReportPeriod,
        shouldCreateCompensatingTransaction: true,
        ledgerDocumentType: this.ledgerDocumentType,
        externalDocumentType: this.externalDocumentType,
      };
      const { previousReconciliationReportId } = this;
      if (previousReconciliationReportId)
        createParams.previousReconciliationReportId = previousReconciliationReportId;
      if (this._ledgerOpeningBalance !== null) {
        createParams.ledgerOB = this._ledgerOpeningBalance;
      }
      if (this._bankOpeningBalance !== null) {
        createParams.externalOB = this._bankOpeningBalance;
      }
      if (this._ledgerDebitIncrease !== null) {
        createParams.ledgerDebitIncrease = this._ledgerDebitIncrease;
      }
      if (this._bankDebitIncrease !== null) {
        createParams.bankDebitIncrease = this._bankDebitIncrease;
      }

      this.status = ReconciliationStatuses.LOADING;
      await this.reconciliationReportRepository.createReconciliationReport(
        createParams,
        (data: CreateReconciliationReportData) => this.onReceivedReconciliationData(data),
        () => this.onReconciliationReportCompleted(),
        (error: any) => {
          this.status = ReconciliationStatuses.ERROR;
          console.error(error);
        }
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  @action
  private isMismatchLedgerError = (code: string): boolean =>
    code === ErrorCodes.MISMATCH_IN_LEDGER_BALANCE_ERROR;

  lockReconciliationReport = async () => {
    try {
      const { reconciliationReportId } = this;
      if (!reconciliationReportId) {
        throw new Error('reconciliationReportId is not defined');
      }
      await this.reconciliationReportRepository.lockReconciliationReport(reconciliationReportId);
    } catch (error: any) {
      console.error(error);
    }
  };

  lockReconciliationReportHandler = (stateResponse: State<null, LockReconciliationError>) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      if (
        this._reconciliationReport &&
        this._reconciliationReport.data &&
        this._reconciliationReport?.data?.status
      ) {
        this.hideLockReconciliationReportDialog();
        this._reconciliationReport.data.status = RECONCILIATION_REPORT_STATUSES.LOCKED;
      }
    }
  };

  unReconcileMatch = async (matchId: string) => {
    try {
      const { reconciliationReportId } = this;
      if (!reconciliationReportId) {
        throw new Error('reconciliationReportId is not defined');
      }
      await this.reconciliationReportRepository.unReconcileMatch(reconciliationReportId, matchId);
    } catch (error: any) {
      console.error(error);
    }
  };

  unReconcileMatchHandler = (stateResponse: State<null, UnReconcileMatchError>) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      this.getReconciliationReport(this.reconciliationReportId!);
    }
  };

  @action
  private onReceivedReconciliationData = (data: CreateReconciliationReportData): void => {
    const { message, id, total, totalReconciled } = data;
    if (message !== undefined && message !== '') this.message = message;
    if (id) this.reconciliationReportId = id;
    if (total !== undefined) this.total = total;
    if (totalReconciled !== undefined) this.totalReconciled = totalReconciled;
  };

  @action
  private onReconciliationReportCompleted = (): void => {
    this.status = ReconciliationStatuses.SUCCESS;
  };

  @action
  getReconciliationReport = (
    reconciliationReportId: string
  ): State<ReconciliationReport | null, ReconciliationReportFetchError> => {
    this._reconciliationReport = {
      ...this._reconciliationReport,
      status: AsyncStatus.PENDING,
    };
    this.cleanManualTransactions();
    this.fetchReconciliationReport(reconciliationReportId);
    return this._reconciliationReport;
  };

  @action
  private async fetchReconciliationReport(reconciliationReportId: string): Promise<void | Error> {
    console.log('fetching reconciliation report');
    try {
      const repoResponse =
        this.reconciliationReportRepository.getReconciliationReport(reconciliationReportId);
      console.log('repoResponse', repoResponse);
      const { status, data } = repoResponse;
      if (status === AsyncStatus.SUCCESS && data) {
        // const fixedReport = this.fixReconciliationReportAmounts(data);
        this._reconciliationReport = fetchedState(data);
      }
      // else if (status === AsyncStatus.CACHED && data)
      //   this._reconciliationReport = cachedState(data, Date.now());
    } catch (error) {
      console.error(error);
    }
  }

  get reconciliationAccountName(): string {
    return this._reconciliationAccount.data ? this._reconciliationAccount.data.name : '';
  }

  get ledgerClosingBalance(): string {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return 'Ledger Closing Balance';
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return 'Ledger Closing Balance';
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return 'Ledger Closing Balance';
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return 'Internal Data / Dataset B Net Movement';
      }

      throw new Error('Unknown reconciliation account type');
    }
    return 'Ledger Cosing Balance';
  }

  get externalClosingBalanceName(): string {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${this.reconciliationAccountName} Closing Balance`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${this.reconciliationAccountName} Closing Balance`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${this.reconciliationAccountName} Closing Balance`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return 'Debits End of Period Balance';
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return `${this.reconciliationAccountName} / Dataset A Net Movement`;
      }

      throw new Error('Unknown reconciliation account type');
    }
    return `${this.reconciliationAccountName} Bank Closing Balance`;
  }

  get ledgerCreditsHeader(): string {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${LEDGER_CREDITS_HEADER} ${this.reconciliationAccountName} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${this.reconciliationAccountName} ${EXTERNAL_CREDITS_HEADER} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${LEDGER_CREDITS_HEADER} ${this.reconciliationAccountName} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return `${CREDITS_NOT_PRESENT} ${this.reconciliationAccountName} Statement`;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return `Internal Data / Dataset B credits not present in ${this.reconciliationAccountName} / Dataset A  Statement`;
      }

      throw new Error('Unknown reconciliation account type');
    }
    return `${LEDGER_CREDITS_HEADER} ${this.reconciliationAccountName} Statement`;
  }

  get ledgerDebitsHeader(): string {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${LEDGER_DEBITS_HEADER} ${this.reconciliationAccountName} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${this.reconciliationAccountName} ${EXTERNAL_DEBITS_HEADER} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${LEDGER_DEBITS_HEADER} ${this.reconciliationAccountName} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return NOT_APPLICABLE;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return `Internal Data / Dataset B debits not present in ${this.reconciliationAccountName} / Dataset A  Statement`;
      }
      throw new Error('Unknown reconciliation account type');
    }
    return `${LEDGER_DEBITS_HEADER} ${this.reconciliationAccountName} Statement`;
  }

  get externalCreditsHeader(): string {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${this.reconciliationAccountName} ${EXTERNAL_CREDITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${LEDGER_CREDITS_HEADER} ${this.reconciliationAccountName}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${this.reconciliationAccountName} ${EXTERNAL_CREDITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return NOT_APPLICABLE;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return `${this.reconciliationAccountName} / Dataset A credits not present in Internal Data / Dataset B  Statement`;
      }

      throw new Error('Unknown reconciliation account type');
    }
    return `${this.reconciliationAccountName} ${EXTERNAL_CREDITS_HEADER}`;
  }

  get externalDebitsHeader(): string {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${this.reconciliationAccountName} ${EXTERNAL_DEBITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${LEDGER_DEBITS_HEADER} ${this.reconciliationAccountName}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${this.reconciliationAccountName} ${EXTERNAL_DEBITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return `${DEBITS_NOT_PRESENT} ${this.reconciliationAccountName} Statement`;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return `${this.reconciliationAccountName} / Dataset A debits not present in Internal Data / Dataset B  Statement`;
      }
      throw new Error('Unknown reconciliation account type');
    }
    return `${this.reconciliationAccountName} ${EXTERNAL_DEBITS_HEADER}`;
  }

  @computed
  get unreconciledTransactions(): UnreconciledTransactionsBlock[] | null {
    if (!this._reconciliationReport.data) {
      return null;
    }

    const sumOfLedgerCredits = this._reconciliationReport.data.ledgerTransactions.reduce(
      (sum, transaction) => sum + transaction.credit,
      0
    );

    const sumOfledgerDebits = this._reconciliationReport.data.ledgerTransactions.reduce(
      (sum, transaction) => sum + transaction.debit,
      0
    );

    const sumOfCreditsBank = this._reconciliationReport.data.externalTransactions.reduce(
      (sum, transaction) => sum + transaction.credit,
      0
    );

    const sumOfDebitsBank = this._reconciliationReport.data.externalTransactions.reduce(
      (sum, transaction) => sum + transaction.debit,
      0
    );

    this.datasetBNetSum = sumOfledgerDebits - sumOfLedgerCredits;
    this.datasetANetSum = sumOfCreditsBank - sumOfDebitsBank;
    const unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] = [];
    // 1. Process ledger credit transactions
    const ledgerUnreconciledTransactions =
      this._reconciliationReport.data.ledgerTransactions.filter(
        (transaction) => transaction.status === ReconciliationTransactionStatuses.Unreconciled
      );
    const ledgerCreditTransactions = ledgerUnreconciledTransactions.filter((transaction) =>
      this.isCreditTransaction(transaction)
    );
    const ledgerCreditTotal = ledgerCreditTransactions.reduce(
      (total, transaction) => total + transaction.credit,
      0
    );
    const ledgerCreditAdjustedBalance = this.computeLedgerCreditAdjustedBalance(ledgerCreditTotal);

    const ledgerCreditBlock: UnreconciledTransactionsBlock = {
      title: this.ledgerCreditsHeader,
      blocks: ledgerCreditTransactions.map((transaction) => ({
        date: DateUtils.FormatYearToYY(transaction.date),
        description: transaction.description,
        reference: transaction.reference,
        amount: this.fixToThousandSeparator(transaction.credit),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'ledger',
      total: this.fixToThousandSeparator(ledgerCreditTotal),
      adjustedBalance: this.fixToThousandSeparator(ledgerCreditAdjustedBalance),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: AMOUNT_TYPE_HEADER.CREDIT,
    };
    unreconciledTransactionsBlocks.push(ledgerCreditBlock);

    // 2. Process ledger debit transactions
    const ledgerDebitTransactions = ledgerUnreconciledTransactions.filter((transaction) =>
      this.isDebitTransaction(transaction)
    );
    const ledgerDebitTotal = ledgerDebitTransactions.reduce(
      (total, transaction) => total + transaction.debit,
      0
    );
    const ledgerDebitAdjustedBalance = ledgerCreditAdjustedBalance - ledgerDebitTotal;
    const ledgerDebitBlock: UnreconciledTransactionsBlock = {
      title: this.ledgerDebitsHeader,
      blocks: ledgerDebitTransactions.map((transaction) => ({
        date: DateUtils.FormatYearToYY(transaction.date),
        description: transaction.description,
        reference: transaction.reference,
        amount: this.fixToThousandSeparator(transaction.debit),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'ledger',
      total: this.fixToThousandSeparator(ledgerDebitTotal),
      adjustedBalance: this.fixToThousandSeparator(ledgerDebitAdjustedBalance),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: AMOUNT_TYPE_HEADER.DEBIT,
    };
    unreconciledTransactionsBlocks.push(ledgerDebitBlock);

    // 3. Process bank credit transactions
    const bankUnreconciledTransactions =
      this._reconciliationReport.data.externalTransactions.filter(
        (transaction) => transaction.status === ReconciliationTransactionStatuses.Unreconciled
      );
    const bankCreditTransactions = bankUnreconciledTransactions.filter((transaction) =>
      this.isCreditTransaction(transaction)
    );

    const bankCreditTotal = bankCreditTransactions.reduce(
      (total, transaction) => total + transaction.credit,
      0
    );
    const bankCreditAdjustedBalance = ledgerDebitAdjustedBalance + bankCreditTotal;
    const bankCreditBlock: UnreconciledTransactionsBlock = {
      title: this.externalCreditsHeader,
      blocks: bankCreditTransactions.map((transaction) => ({
        date: DateUtils.FormatYearToYY(transaction.date),
        description: transaction.description,
        reference: transaction.reference,
        amount: this.fixToThousandSeparator(transaction.credit),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'bank',
      total: this.fixToThousandSeparator(bankCreditTotal),
      adjustedBalance: this.fixToThousandSeparator(bankCreditAdjustedBalance),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: AMOUNT_TYPE_HEADER.CREDIT,
    };
    unreconciledTransactionsBlocks.push(bankCreditBlock);

    // 4. Process bank debit transactions
    const bankDebitTransactions = bankUnreconciledTransactions.filter((transaction) =>
      this.isDebitTransaction(transaction)
    );
    const bankDebitTotal = bankDebitTransactions.reduce(
      (total, transaction) => total + transaction.debit,
      0
    );
    const bankDebitAdjustedBalance = bankCreditAdjustedBalance - bankDebitTotal;
    const bankDebitBlock: UnreconciledTransactionsBlock = {
      title: this.externalDebitsHeader,
      blocks: bankDebitTransactions.map((transaction) => ({
        date: DateUtils.FormatYearToYY(transaction.date),
        description: transaction.description,
        reference: transaction.reference,
        amount: this.fixToThousandSeparator(transaction.debit),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'bank',
      total: this.fixToThousandSeparator(bankDebitTotal),
      adjustedBalance: this.fixToThousandSeparator(bankDebitAdjustedBalance),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: AMOUNT_TYPE_HEADER.DEBIT,
    };
    unreconciledTransactionsBlocks.push(bankDebitBlock);
    this.reconciliationDifference = this.computeReconciliationDifference(bankDebitAdjustedBalance);
    return unreconciledTransactionsBlocks;
  }

  private computeLedgerCreditAdjustedBalance = (ledgerCreditTotal: number): number => {
    if (!this._reconciliationReport.data) {
      return 0;
    }

    if (
      this._reconciliationAccount.data?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      return this.datasetBNetSum + ledgerCreditTotal;
    }
    return this._reconciliationReport.data.ledgerEndBalance + ledgerCreditTotal;
  };

  private computeReconciliationDifference = (bankDebitAdjustedBalance: number): string => {
    if (
      this._reconciliationAccount.data?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      const balance = bankDebitAdjustedBalance - this.datasetANetSum;
      return this.fixToThousandSeparator(balance);
    }
    if (this._reconciliationReport.data && this._reconciliationReport.data.bankClosingBalance) {
      return this.fixToThousandSeparator(
        bankDebitAdjustedBalance - this._reconciliationReport.data.bankClosingBalance
      );
    }
    return '0';
  };

  set unreconciledLedgerTransactions(unreconciledLedgerTransactions: ManualTransaction[]) {
    this._unreconciledLedgerTransactions = unreconciledLedgerTransactions;
  }

  @computed
  get unreconciledLedgerTransactions(): ManualTransaction[] {
    const unreconciledLedgerTransactions: ManualTransaction[] = [];

    if (
      this._unreconciledLedgerTransactions.length > 0 ||
      this._ledgerTransactionsAreManuallyReconciled
    ) {
      return this._unreconciledLedgerTransactions;
    }
    if (this._reconciliationReport.data) {
      this._reconciliationReport.data.ledgerTransactions.forEach((transaction) => {
        if (transaction.status === ReconciliationTransactionStatuses.Unreconciled) {
          unreconciledLedgerTransactions.push(this.fixManualTransactionAmountsAndDate(transaction));
        }
      });
    }
    this._unreconciledLedgerTransactions = unreconciledLedgerTransactions;
    return this._unreconciledLedgerTransactions;
  }

  @computed
  get unreconciledUnselectedLedgerTransactions(): ManualTransaction[] {
    return this.unreconciledLedgerTransactions.filter(
      (transaction) => !transaction.selected && !this.isTransactionOfZeroAmount(transaction)
    );
  }

  set unreconciledBankTransactions(unreconciledBankTransactions: ManualTransaction[]) {
    this._unreconciledBankTransactions = unreconciledBankTransactions;
  }

  @computed
  get unreconciledBankTransactions(): ManualTransaction[] {
    const unreconciledBankTransactions: ManualTransaction[] = [];

    if (
      this._unreconciledBankTransactions.length > 0 ||
      this._bankTransactionsAreManuallyReconciled
    ) {
      return this._unreconciledBankTransactions;
    }
    if (this._reconciliationReport.data) {
      this._reconciliationReport.data.externalTransactions.forEach((transaction) => {
        if (transaction.status === ReconciliationTransactionStatuses.Unreconciled) {
          unreconciledBankTransactions.push(this.fixManualTransactionAmountsAndDate(transaction));
        }
      });
    }
    this.unreconciledBankTransactions = unreconciledBankTransactions;
    return this._unreconciledBankTransactions;
  }

  @computed
  get unreconciledUnselectedBankTransactions(): ManualTransaction[] {
    return this.unreconciledBankTransactions.filter(
      (transaction) => !transaction.selected && !this.isTransactionOfZeroAmount(transaction)
    );
  }

  @computed
  get unreconciledUnselectedBankTransactionsFiltered(): ManualTransaction[] {
    let transactionsFiltered = this.unreconciledUnselectedBankTransactions;
    if (this._bankFilterOptionsApplied) {
      transactionsFiltered = ManualTransactionFilter.applyFilterOptions(
        this._bankFilterOptions,
        this.unreconciledUnselectedBankTransactions
      );
    }
    if (this._bankQuickFilter.length >= 3) {
      return ManualTransactionFilter.runQuery(this._bankQuickFilter, transactionsFiltered);
    }
    return transactionsFiltered;
  }

  @computed
  get unreconciledUnselectedLedgerTransactionsFiltered(): ManualTransaction[] {
    let transactionsFiltered = this.unreconciledUnselectedLedgerTransactions;
    if (this._ledgerFilterOptionsApplied) {
      transactionsFiltered = ManualTransactionFilter.applyFilterOptions(
        this._ledgerFilterOptions,
        this.unreconciledUnselectedLedgerTransactions
      );
    }
    if (this._ledgerQuickFilter.length >= 3) {
      return ManualTransactionFilter.runQuery(this._ledgerQuickFilter, transactionsFiltered);
    }
    return transactionsFiltered;
  }

  private clearQuickFilters = () => {
    this.clearBankQuickFilter();
    this.clearLedgerQuickFilter();
  };

  private clearBankQuickFilter = () => {
    this._bankQuickFilter = '';
  };

  private clearLedgerQuickFilter = () => {
    this._ledgerQuickFilter = '';
  };

  changeBankQuickFilter = (value: string) => {
    this._bankQuickFilter = value;
  };

  changeLedgerQuickFilter = (value: string) => {
    this._ledgerQuickFilter = value;
  };

  private setBankDateDropdownOpen = (value: boolean) => {
    this._isBankDateDropdownOpen = value;
  };

  private setBankDateAscending = (value: boolean) => {
    this._isBankDateAscending = value;
  };

  private isTransactionOfZeroAmount = (transaction: ManualTransaction): boolean => {
    const creditIsZeroOrNull =
      transaction.credit === '0' ||
      (transaction.credit === '0.00' &&
        (!transaction.debit || transaction.debit === '0' || transaction.debit === '0.00'));
    const debitIsZeroOrNull =
      (transaction.debit === '0' || transaction.debit === '0.00') &&
      (!transaction.credit || transaction.credit === '0' || transaction.credit === '0.00');
    return creditIsZeroOrNull && debitIsZeroOrNull;
  };

  toggleBankDateDropdown = () => {
    this.setBankDateDropdownOpen(!this._isBankDateDropdownOpen);
    this.setBankDateAscending(!this._isBankDateAscending);
    this._unreconciledBankTransactions = this.orderbyDate(
      this._unreconciledBankTransactions,
      this._isBankDateAscending
    );
  };

  private orderbyDate = (
    unreconciledTransactions: ManualTransaction[],
    isDateAscending: boolean
  ): ManualTransaction[] => {
    const sortedTransactions = unreconciledTransactions.sort((a, b) => {
      const dateA = DateUtils.ParseCustomDate(a.date);
      const dateB = DateUtils.ParseCustomDate(b.date);
      return isDateAscending
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });
    return sortedTransactions;
  };

  private setBankCreditDropdownOpen = (value: boolean) => {
    this._isBankCreditDropdownOpen = value;
  };

  private setBankCreditAscending = (value: boolean) => {
    this._isBankCreditAscending = value;
  };

  toggleBankCreditDropdown = () => {
    this.setBankCreditDropdownOpen(!this._isBankCreditDropdownOpen);
    this.setBankCreditAscending(!this._isBankCreditAscending);
    this._unreconciledBankTransactions = this.orderbyCredit(
      this._unreconciledBankTransactions,
      this._isBankCreditAscending
    );
  };

  private orderbyCredit = (
    unreconciledTransactions: ManualTransaction[],
    isCreditAscending: boolean
  ): ManualTransaction[] => {
    const sortedTransactions = unreconciledTransactions.sort((a, b) =>
      isCreditAscending ? a.creditNumber - b.creditNumber : b.creditNumber - a.creditNumber
    );
    return sortedTransactions;
  };

  private setBankDebitDropdownOpen = (value: boolean) => {
    this._isBankDebitDropdownOpen = value;
  };

  private setBankDebitAscending = (value: boolean) => {
    this._isBankDebitAscending = value;
  };

  toggleBankDebitDropdown = () => {
    this.setBankDebitDropdownOpen(!this._isBankDebitDropdownOpen);
    this.setBankDebitAscending(!this._isBankDebitAscending);
    this._unreconciledBankTransactions = this.orderbyDebit(
      this._unreconciledBankTransactions,
      this._isBankDebitAscending
    );
  };

  private orderbyDebit = (
    unreconciledTransactions: ManualTransaction[],
    isDebitAscending: boolean
  ): ManualTransaction[] => {
    const sortedTransactions = unreconciledTransactions.sort((a, b) =>
      isDebitAscending ? a.debitNumber - b.debitNumber : b.debitNumber - a.debitNumber
    );
    return sortedTransactions;
  };

  private setLedgerDateDropdownOpen = (value: boolean) => {
    this._isLedgerDateDropdownOpen = value;
  };

  private setLedgerDateAscending = (value: boolean) => {
    this._isLedgerDateAscending = value;
  };

  toggleLedgerDateDropdown = () => {
    this.setLedgerDateDropdownOpen(!this._isLedgerDateDropdownOpen);
    this.setLedgerDateAscending(!this._isLedgerDateAscending);
    this._unreconciledLedgerTransactions = this.orderbyDate(
      this._unreconciledLedgerTransactions,
      this._isLedgerDateAscending
    );
  };

  private setLedgerCreditDropdownOpen = (value: boolean) => {
    this._isLedgerCreditDropdownOpen = value;
  };

  private setLedgerCreditAscending = (value: boolean) => {
    this._isLedgerCreditAscending = value;
  };

  toggleLedgerCreditDropdown = () => {
    this.setLedgerCreditDropdownOpen(!this._isLedgerCreditDropdownOpen);
    this.setLedgerCreditAscending(!this._isLedgerCreditAscending);
    this._unreconciledLedgerTransactions = this.orderbyCredit(
      this._unreconciledLedgerTransactions,
      this._isLedgerCreditAscending
    );
  };

  private setLedgerDebitDropdownOpen = (value: boolean) => {
    this._isLedgerDebitDropdownOpen = value;
  };

  private setLedgerDebitAscending = (value: boolean) => {
    this._isLedgerDebitAscending = value;
  };

  toggleLedgerDebitDropdown = () => {
    this.setLedgerDebitDropdownOpen(!this._isLedgerDebitDropdownOpen);
    this.setLedgerDebitAscending(!this._isLedgerDebitAscending);
    this._unreconciledLedgerTransactions = this.orderbyDebit(
      this._unreconciledLedgerTransactions,
      this._isLedgerDebitAscending
    );
  };

  @action
  cleanManualTransactions = () => {
    this.cleanManualBankTransactions();
    this.cleanManualLedgerTransactions();
  };

  @action
  cleanManualBankTransactions = () => {
    this.unreconciledBankTransactions = [];
    this._bankTransactionsAreManuallyReconciled = false;
  };

  @action
  cleanManualLedgerTransactions = () => {
    this.unreconciledLedgerTransactions = [];
    this._ledgerTransactionsAreManuallyReconciled = false;
  };

  @action
  selectBankTransaction = (rowId: number) => {
    const unreconciledBankTransactions: ManualTransaction[] = this._unreconciledBankTransactions;
    const bankTransaction = unreconciledBankTransactions.find(
      (transaction) => transaction.rowId === rowId
    );
    if (bankTransaction) {
      bankTransaction.selected = true;
    }
    this._unreconciledBankTransactions = unreconciledBankTransactions;
  };

  @action
  unselectBankTransaction = (rowId: number) => {
    const unreconciledBankTransactions: ManualTransaction[] = this._unreconciledBankTransactions;
    const bankTransaction = unreconciledBankTransactions.find(
      (transaction) => transaction.rowId === rowId
    );
    if (bankTransaction) {
      bankTransaction.selected = false;
    }
  };

  selectOrUnselectBankTransaction = (bankTransaction: ManualTransaction) => {
    if (bankTransaction.selected) {
      this.unselectBankTransaction(bankTransaction.rowId);
    } else {
      this.selectBankTransaction(bankTransaction.rowId);
    }
  };

  @computed
  selectLedgerTransaction = (rowId: number) => {
    const unreconciledLedgerTransactions: ManualTransaction[] =
      this._unreconciledLedgerTransactions;

    const ledgerTransaction = unreconciledLedgerTransactions.find(
      (transaction) => transaction.rowId === rowId
    );
    if (ledgerTransaction) {
      ledgerTransaction.selected = true;
    }
  };

  @action
  unselectLedgerTransaction = (rowId: number) => {
    const unreconciledLedgerTransactions: ManualTransaction[] =
      this._unreconciledLedgerTransactions;

    const ledgerTransaction = unreconciledLedgerTransactions.find(
      (transaction) => transaction.rowId === rowId
    );
    if (ledgerTransaction) {
      ledgerTransaction.selected = false;
    }
  };

  selectOrUnselectLedgerTransaction = (ledgerTransaction: ManualTransaction) => {
    if (ledgerTransaction.selected) {
      this.unselectLedgerTransaction(ledgerTransaction.rowId);
    } else {
      this.selectLedgerTransaction(ledgerTransaction.rowId);
    }
  };

  unselectSelectedTransactions = () => {
    const unreconciledBankTransactions: ManualTransaction[] = [];
    const unreconciledLedgerTransactions: ManualTransaction[] = [];

    this._unreconciledBankTransactions.forEach((transaction) => {
      unreconciledBankTransactions.push({ ...transaction, selected: false });
    });
    this._unreconciledBankTransactions = unreconciledBankTransactions;

    this._unreconciledLedgerTransactions.forEach((transaction) => {
      unreconciledLedgerTransactions.push({ ...transaction, selected: false });
    });
    this._unreconciledLedgerTransactions = unreconciledLedgerTransactions;
  };

  generateCorrespondingTransactions = async () => {
    if (this.reconciliationReportId) {
      const ledgerTransactions: CreateLedgerTransaction[] =
        this._selectedTransactions.bankTransactions.map((transaction) => {
          const {
            date,
            description,
            reference,
            creditNumber,
            debitNumber,
            financialTransactionId,
          } = transaction;
          let amount;
          let transactionType;
          if (ManualTransactionHelper.isCredit(transaction)) {
            amount = creditNumber;
            transactionType = AMOUNT_TYPE.DEBIT;
          } else {
            amount = debitNumber;
            transactionType = AMOUNT_TYPE.CREDIT;
          }
          return {
            date: DateUtils.FormatYearToYYYY(date),
            description,
            reference,
            amount,
            transactionType,
            financialTransactionId,
          };
        });
      const createLedgerTransactionsParams: CreateLedgerTransactionsParams = {
        reconciliationReportId: this.reconciliationReportId,
        ledgerTransactions,
      };
      this.autoGeneratedLedgerTransactions = ledgerTransactions;
      await this.reconciliationReportRepository.createLedgerTransactions(
        createLedgerTransactionsParams
      );
    }
  };

  set autoGeneratedLedgerTransactions(autoGeneratedLedgerTransactions: CreateLedgerTransaction[]) {
    this._autoGeneratedLedgerTransactions = autoGeneratedLedgerTransactions;
  }

  set selectedTransactions(selectedTransactions: SelectedTransactions) {
    this._selectedTransactions = selectedTransactions;
  }

  get selectedTransactions(): SelectedTransactions {
    const selectedTransactions: SelectedTransactions = {
      bankTransactions: [],
      ledgerTransactions: [],
    };
    if (this._unreconciledBankTransactions) {
      selectedTransactions.bankTransactions = this._unreconciledBankTransactions.filter(
        (transaction) => transaction.selected
      );
    }
    if (this._unreconciledLedgerTransactions) {
      selectedTransactions.ledgerTransactions = this._unreconciledLedgerTransactions.filter(
        (transaction) => transaction.selected
      );
    }
    this.selectedTransactions = selectedTransactions;
    return selectedTransactions;
  }

  @computed
  get reconciledTransactions(): ReconciledTransactionsBlock[] | null {
    if (!this._reconciliationReport.data) {
      return null;
    }

    let { matches } = this._reconciliationReport.data;
    if (matches.length > 2000) {
      console.log('matches length over 2000', matches.length);
      matches = matches.slice(0, 2000);
      console.log('matches length after slice', matches.length);
    }

    const sortedMatches = matches.slice().sort((a, b) => {
      const minDateBankTransactionA = a.bankStatementTransactions.slice().sort((bt1, bt2) => {
        if (bt1?.date && bt2?.date) {
          return (
            DateUtils.ParseCustomDate(bt1.date).getTime() -
            DateUtils.ParseCustomDate(bt2.date).getTime()
          );
        }
        return 0;
      });

      const minDateBankTransactionB = b.bankStatementTransactions.slice().sort((bt1, bt2) => {
        if (bt1?.date && bt2?.date) {
          return (
            DateUtils.ParseCustomDate(bt1.date).getTime() -
            DateUtils.ParseCustomDate(bt2.date).getTime()
          );
        }
        return 0;
      });

      if (!minDateBankTransactionA[0]?.date || !minDateBankTransactionB[0]?.date) {
        return 0;
      }

      return (
        DateUtils.ParseCustomDate(minDateBankTransactionA[0].date).getTime() -
        DateUtils.ParseCustomDate(minDateBankTransactionB[0].date).getTime()
      );
    });

    const reconciledTransactionsBlocks: ReconciledTransactionsBlock[] = sortedMatches.map(
      (match) => {
        const reconciledBlock: ReconciledTransactionsBlock = {
          id: match.id,
          bankStatementTransactions: this.fixTransactionAmountsAndDate(
            match.bankStatementTransactions
          ),
          ledgerTransactions: this.fixTransactionAmountsAndDate(match.ledgerTransactions),
          type: match.type,
        };
        return reconciledBlock;
      }
    );
    return reconciledTransactionsBlocks;
  }

  @computed
  get bankStatementTransactionsPercentage(): number | null {
    if (!this._reconciliationReport.data) {
      return null;
    }

    let automaticReconciledOrSuggestedBankTransactions = 0;
    this._reconciliationReport.data.matches.forEach((match) => {
      if (match.type !== RECONCILIATION_MATCH_TYPES.MANUAL) {
        match.bankStatementTransactions.forEach((matchBankTransaction) => {
          automaticReconciledOrSuggestedBankTransactions += 1;
        });
      }
    });
    const totalBankTransactions = this._reconciliationReport.data.externalTransactions.length;
    const percentage =
      (automaticReconciledOrSuggestedBankTransactions / totalBankTransactions) * 100;

    return percentage;
  }

  @computed
  get ledgerEndBalance(): string {
    if (this._reconciliationReport.data) {
      if (
        this._reconciliationAccount.data?.reconciliationType ===
        RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
      ) {
        const sumOfLedgerCredits = this._reconciliationReport.data?.ledgerTransactions.reduce(
          (sum, transaction) => sum + transaction.credit,
          0
        );

        const sumOfledgerDebits = this._reconciliationReport.data?.ledgerTransactions.reduce(
          (sum, transaction) => sum + transaction.debit,
          0
        );

        const netMovementDatasetB = sumOfledgerDebits - sumOfLedgerCredits;
        return this.fixToThousandSeparator(netMovementDatasetB);
      }
      return this.fixToThousandSeparator(this._reconciliationReport.data.ledgerEndBalance);
    }
    return '';
  }

  @computed
  get bankClosingBalance(): string {
    if (this._reconciliationReport.data) {
      if (
        this._reconciliationAccount.data?.reconciliationType ===
        RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
      ) {
        const sumOfExternalCredits = this._reconciliationReport.data?.externalTransactions.reduce(
          (sum, transaction) => sum + transaction.credit,
          0
        );

        const sumOfExternalDebits = this._reconciliationReport.data?.externalTransactions.reduce(
          (sum, transaction) => sum + transaction.debit,
          0
        );

        const netMovementDatasetA = sumOfExternalCredits - sumOfExternalDebits;
        return this.fixToThousandSeparator(netMovementDatasetA);
      }
      this._bankClosingBalance = this.fixToThousandSeparator(
        this._reconciliationReport.data.bankClosingBalance
      );
    }
    return this._bankClosingBalance;
  }

  @computed
  get reconciliationDifference(): string {
    return this._reconciliationDifference;
  }

  private set reconciliationDifference(value: string) {
    this._reconciliationDifference = value;
  }

  set manualReconciliationDifference(value: string) {
    this._manualReconciliationDifference = value;
  }

  get manualReconciliationDifference(): string {
    let totalLedgerAmount = 0;
    this._selectedTransactions.ledgerTransactions.forEach((ledgerFinancialTransaction) => {
      const { debitNumber, creditNumber } = ledgerFinancialTransaction;
      if (ManualTransactionHelper.isCredit(ledgerFinancialTransaction)) {
        totalLedgerAmount -= creditNumber;
      }
      if (ManualTransactionHelper.isDebit(ledgerFinancialTransaction)) {
        totalLedgerAmount += debitNumber;
      }
    });
    let totalExternalAmount = 0;
    this._selectedTransactions.bankTransactions.forEach((externalFinancialTransaction) => {
      const { debitNumber, creditNumber } = externalFinancialTransaction;
      if (ManualTransactionHelper.isCredit(externalFinancialTransaction)) {
        totalExternalAmount += creditNumber;
      }
      if (ManualTransactionHelper.isDebit(externalFinancialTransaction)) {
        totalExternalAmount -= debitNumber;
      }
    });
    const totalAmount = totalLedgerAmount - totalExternalAmount;
    const roundedAmount = Math.round(totalAmount * 100) / 100;
    this._manualReconciliationDifferenceNumber = roundedAmount;
    this.manualReconciliationDifference = this.fixToThousandSeparator(roundedAmount);
    return this._manualReconciliationDifference;
  }

  get manualReconciliationDifferenceLabel(): string {
    const label = 'Missing: Bank or Ledger';
    const absoluteDifference = Math.abs(this._manualReconciliationDifferenceNumber);
    const toThousandDifference = this.fixToThousandSeparator(absoluteDifference);
    return this._manualReconciliationDifferenceNumber > 0
      ? `${label} Credit(s) ${toThousandDifference}`
      : `${label} Debit(s) ${toThousandDifference}`;
  }

  set manualReconcilableStatus(status: ManualReconcilableStatus) {
    this._manualReconcilableStatus = status;
  }

  get manualReconcilableStatus(): ManualReconcilableStatus {
    if (
      this._selectedTransactions.ledgerTransactions.length === 0 &&
      this._selectedTransactions.bankTransactions.length === 0
    ) {
      this.manualReconcilableStatus = MANUAL_RECONCILABLE_STATUSES.IDLE;
    } else if (
      this._manualReconciliationDifference === '0.00' &&
      this._manualReconcilableStatus !== MANUAL_RECONCILABLE_STATUSES.IDLE
    ) {
      this.manualReconcilableStatus = MANUAL_RECONCILABLE_STATUSES.RECONCILABLE;
    } else {
      this.manualReconcilableStatus = MANUAL_RECONCILABLE_STATUSES.UNRECONCILABLE;
    }
    return this._manualReconcilableStatus;
  }

  get reconciliationAccountData() {
    return this._reconciliationAccount.data;
  }

  setReconciliationReportId = (id: string) => {
    this.reconciliationReportId = id;
  };

  setReconciliationAccount = (
    reconciliationAccount: State<ReconciliationAccount | null, ReconciliationAccountFetchError>
  ) => {
    this._reconciliationAccount = reconciliationAccount;
  };

  @action
  manuallyReconcile = async (): Promise<void> => {
    try {
      if (this.reconciliationReportId) {
        const bankTransactionRowIds = this._selectedTransactions.bankTransactions.map(
          (bankTransaction) => bankTransaction.rowId
        );
        const ledgerTransactionRowIds = this._selectedTransactions.ledgerTransactions.map(
          (ledgerTransaction) => ledgerTransaction.rowId
        );
        await this.reconciliationReportRepository.manualReconciliation(
          this.reconciliationReportId,
          ledgerTransactionRowIds,
          bankTransactionRowIds
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  manuallyReconciledHandler = (stateResponse: State<null, ManualReconciliationError>) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      const bankTransactionRowIds = this._selectedTransactions.bankTransactions.map(
        (bankTransaction) => bankTransaction.rowId
      );
      const ledgerTransactionRowIds = this._selectedTransactions.ledgerTransactions.map(
        (ledgerTransaction) => ledgerTransaction.rowId
      );
      this._unreconciledBankTransactions = this._unreconciledBankTransactions.filter(
        (bankTransaction) => !bankTransactionRowIds.includes(bankTransaction.rowId)
      );
      this._bankTransactionsAreManuallyReconciled = true;
      this._unreconciledLedgerTransactions = this._unreconciledLedgerTransactions.filter(
        (ledgerTransaction) => !ledgerTransactionRowIds.includes(ledgerTransaction.rowId)
      );
      this._ledgerTransactionsAreManuallyReconciled = true;
    }
  };

  deleteLedgerTransaction = async (): Promise<void> => {
    if (this.reconciliationReportId) {
      const deleteLedgerTransactionParams: DeleteLedgerTransactionParams = {
        rowId: this._editLedgerTransactionParams.rowId,
        reconciliationReportId: this.reconciliationReportId,
      };
      this.ledgerTransactionEditStatus = AsyncStatus.PENDING;
      await this.reconciliationReportRepository.deleteLedgerTransaction(
        deleteLedgerTransactionParams
      );
    }
  };

  @action
  private deleteLedgerTransactionHandler = (
    stateResponse: State<number, DeleteLedgerTransactionError>
  ) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      this.ledgerTransactionEditStatus = AsyncStatus.SUCCESS;
      this.hideEditLedgerTransactionDialog();
      const ledgerTransactionRowId = stateResponse.data;

      this.unreconciledLedgerTransactions = this._unreconciledLedgerTransactions.filter(
        (ledgerTransaction) => ledgerTransaction.rowId !== ledgerTransactionRowId
      );

      this._editLedgerTransactionParams =
        ReconciliationReportViewModel.initializeManualTransactionParams();
    }
  };

  editLedgerTransaction = async (): Promise<void> => {
    console.log('editLedgerTransaction');
    if (this.reconciliationReportId) {
      const editLedgerTransactionParams: EditLedgerTransactionParams = {
        date: this._editLedgerTransactionParams.date,
        description: this._editLedgerTransactionParams.description,
        reference: this._editLedgerTransactionParams.reference,
        amount: this._editLedgerTransactionParams.amount,
        transactionType: this._editLedgerTransactionParams.transactionType,
        financialTransactionId: this._editLedgerTransactionParams.financialTransactionId,
        rowId: this._editLedgerTransactionParams.rowId,
        reconciliationReportId: this.reconciliationReportId,
      };
      if (!this.areEditLedgerTransactionRequiredFieldsSet) {
        throw new Error('Ledger Transaction required fields are not set');
      }
      this.ledgerTransactionEditStatus = AsyncStatus.PENDING;
      await this.reconciliationReportRepository.editLedgerTransaction(editLedgerTransactionParams);
    }
  };

  @action
  private editLedgerTransactionHandler = (
    stateResponse: State<number, EditLedgerTransactionError>
  ) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      this.ledgerTransactionEditStatus = AsyncStatus.SUCCESS;
      this.hideEditLedgerTransactionDialog();
      const ledgerTransactionRowId = stateResponse.data;
      const editedLedgerTransaction = this.mapLedgerTransactionToManualTransaction(
        this._editLedgerTransactionParams,
        ledgerTransactionRowId
      );
      this.unreconciledLedgerTransactions = this._unreconciledLedgerTransactions.map(
        (ledgerTransaction) => {
          if (ledgerTransaction.rowId === ledgerTransactionRowId) {
            return editedLedgerTransaction;
          }
          return ledgerTransaction;
        }
      );

      this._editLedgerTransactionParams =
        ReconciliationReportViewModel.initializeManualTransactionParams();
    }
  };

  get areLedgerTransactionRequiredFieldsSet() {
    return (
      this._draftLedgerTransaction.date !== INVALID_DATE &&
      this._draftLedgerTransaction.description !== '' &&
      this._draftLedgerTransaction.transactionType !== '' &&
      +this._draftLedgerTransaction.amount !== 0
    );
  }

  get isNewLedgerTransactionDialogVisible(): boolean {
    return this._showNewLedgerTransactionDialog;
  }

  get isBankFilterPopUpDialogVisible(): boolean {
    return this._showBankFilterPopUpDialog;
  }

  get isLedgerFilterPopUpDialogVisible(): boolean {
    return this._showLedgerFilterPopUpDialog;
  }

  get areBankFilterOptionsSet() {
    return (
      this._bankFilterOptions.date.from !== INVALID_DATE ||
      this._bankFilterOptions.date.to !== INVALID_DATE ||
      this._bankFilterOptions.amount.from !== null ||
      this._bankFilterOptions.amount.to !== null ||
      this._bankFilterOptions.search !== ''
    );
  }

  get areLedgerFilterOptionsSet() {
    return (
      this._ledgerFilterOptions.date.from !== INVALID_DATE ||
      this._ledgerFilterOptions.date.to !== INVALID_DATE ||
      this._ledgerFilterOptions.amount.from !== null ||
      this._ledgerFilterOptions.amount.to !== null ||
      this._ledgerFilterOptions.search !== ''
    );
  }

  get ledgerTransactionTypes() {
    return [AMOUNT_TYPE.DEBIT, AMOUNT_TYPE.CREDIT];
  }

  get areLedgerMissingOpeningBalanceRequiredFieldsSet() {
    return this._ledgerOpeningBalance !== null;
  }

  get areBankMissingOpeningBalanceRequiredFieldsSet() {
    return this._bankOpeningBalance !== null;
  }

  private isDebitTransaction(transaction: Transaction): boolean {
    return transaction.debit !== 0;
  }

  private isCreditTransaction(transaction: Transaction): boolean {
    return transaction.credit !== 0;
  }

  @action
  editLedgerOpeningBalance = (ob: string): void => {
    const amountNumber = parseFloat(ob);
    if (Number.isNaN(amountNumber)) {
      this._ledgerOpeningBalance = null;
    } else {
      this._ledgerOpeningBalance = amountNumber;
    }
  };

  @action
  editBankOpeningBalance = (ob: string): void => {
    const amountNumber = parseFloat(ob);
    if (Number.isNaN(amountNumber)) {
      this._bankOpeningBalance = null;
    } else {
      this._bankOpeningBalance = amountNumber;
    }
  };

  @action
  setSearchBankFilterOptions = (search: string): void => {
    this._bankFilterOptions.search = search;
  };

  @action
  setSearchLedgerFilterOptions = (search: string): void => {
    this._ledgerFilterOptions.search = search;
  };

  @action
  setFromAmountBankFilterOptions = (amount: string): void => {
    const amountNumber = parseFloat(amount);
    if (Number.isNaN(amountNumber)) {
      this._bankFilterOptions.amount.from = null;
    } else {
      this._bankFilterOptions.amount.from = amountNumber;
    }
  };

  @action
  setToAmountBankFilterOptions = (amount: string): void => {
    const amountNumber = parseFloat(amount);
    if (Number.isNaN(amountNumber)) {
      this._bankFilterOptions.amount.to = null;
    } else {
      this._bankFilterOptions.amount.to = amountNumber;
    }
  };

  @action
  setFromAmountLedgerFilterOptions = (amount: string): void => {
    const amountNumber = parseFloat(amount);
    if (Number.isNaN(amountNumber)) {
      this._ledgerFilterOptions.amount.from = null;
    } else {
      this._ledgerFilterOptions.amount.from = amountNumber;
    }
  };

  @action
  setToAmountLedgerFilterOptions = (amount: string): void => {
    const amountNumber = parseFloat(amount);
    if (Number.isNaN(amountNumber)) {
      this._ledgerFilterOptions.amount.to = null;
    } else {
      this._ledgerFilterOptions.amount.to = amountNumber;
    }
  };

  @action
  applyBankFilterOptions = (): void => {
    this.clearBankQuickFilter();
    this.hideBankFilterPopUpDialog();
    this._bankFilterOptionsApplied = true;
  };

  @action
  applyLedgerFilterOptions = (): void => {
    this.clearLedgerQuickFilter();
    this.hideLedgerFilterPopUpDialog();
    this._ledgerFilterOptionsApplied = true;
  };

  @action
  clearBankFilterOptions = (): void => {
    this._bankFilterOptions = ReconciliationReportViewModel.initializeFilterOptions();
    this.hideBankFilterPopUpDialog();
    this._bankFilterOptionsApplied = false;
  };

  @action
  clearLedgerFilterOptions = (): void => {
    this._ledgerFilterOptions = ReconciliationReportViewModel.initializeFilterOptions();
    this.hideLedgerFilterPopUpDialog();
    this._ledgerFilterOptionsApplied = false;
  };

  @action
  private clearFilterOptions = (): void => {
    this.clearBankFilterOptions();
    this.clearLedgerFilterOptions();
  };

  @action
  public clearAllFilters = (): void => {
    this.clearQuickFilters();
    this.clearFilterOptions();
  };

  @action
  showEditLedgerTransactionDialog = (ledgerTransaction: ManualTransaction): void => {
    let amountType = '';
    let amount = 0;
    if (ManualTransactionHelper.isDebit(ledgerTransaction)) {
      amountType = AMOUNT_TYPE.DEBIT;
      amount = ledgerTransaction.debitNumber;
    } else if (ManualTransactionHelper.isCredit(ledgerTransaction)) {
      amountType = AMOUNT_TYPE.CREDIT;
      amount = ledgerTransaction.creditNumber;
    }
    this._editLedgerTransactionParams = {
      date: DateUtils.FormatYearToYYYY(ledgerTransaction.date),
      description: ledgerTransaction.description,
      amount: amount.toString(),
      transactionType: amountType,
      rowId: ledgerTransaction.rowId,
      financialTransactionId: ledgerTransaction.financialTransactionId,
      reference: ledgerTransaction.reference,
    };
    this._showEditLedgerTransactionDialog = true;
  };

  @action
  hideEditLedgerTransactionDialog = (): void => {
    this._showEditLedgerTransactionDialog = false;
  };

  @action
  editLedgerTransactionDate = (date: string): void => {
    this._editLedgerTransactionParams.date = date;
  };

  @action
  editLedgerTransactionDescription = (description: string): void => {
    this._editLedgerTransactionParams.description = description;
  };

  @action
  editLedgerTransactionReference = (reference: string): void => {
    this._editLedgerTransactionParams.reference = reference;
  };

  @action
  editLedgerTransactionAmount = (amount: string): void => {
    this._editLedgerTransactionParams.amount = amount;
  };

  @action
  editLedgerTransactionType = (type: string): void => {
    this._editLedgerTransactionParams.transactionType = type;
  };

  @action
  editLedgerTransactionFinancialTransactionId = (id: string): void => {
    this._editLedgerTransactionParams.financialTransactionId = id;
  };

  @action
  showNewLedgerTransactionDialog = (): void => {
    console.log('showNewLedgerTransactionDialog', this._showNewLedgerTransactionDialog);
    this._showNewLedgerTransactionDialog = true;
  };

  @action
  hideNewLedgerTransactionDialog = (): void => {
    this._showNewLedgerTransactionDialog = false;
  };

  @action
  showBankFilterPopUpDialog = (): void => {
    this._showBankFilterPopUpDialog = true;
    this._bankFilterOptionsApplied = false;
  };

  @action
  hideBankFilterPopUpDialog = (): void => {
    this._showBankFilterPopUpDialog = false;
  };

  @action
  showLedgerFilterPopUpDialog = (): void => {
    this._showLedgerFilterPopUpDialog = true;
    this._ledgerFilterOptionsApplied = false;
  };

  @action
  hideLedgerFilterPopUpDialog = (): void => {
    this._showLedgerFilterPopUpDialog = false;
  };

  @action
  selectFilteredBankUnreconciledManualTransactions = (): void => {
    const unreconciledBankTransactions: ManualTransaction[] = [];

    for (let i = 0; i < this._unreconciledBankTransactions.length; i += 1) {
      const bankTransaction = this._unreconciledBankTransactions[i];
      const bankTransactionFiltered = this.unreconciledUnselectedBankTransactionsFiltered.find(
        (transaction) => transaction.rowId === bankTransaction.rowId
      );
      if (bankTransactionFiltered) {
        unreconciledBankTransactions.push({ ...bankTransaction, selected: true });
      } else {
        unreconciledBankTransactions.push(bankTransaction);
      }
    }
    this._unreconciledBankTransactions = unreconciledBankTransactions;
  };

  @action
  selectFilteredLedgerUnreconciledManualTransactions = (): void => {
    const unreconciledLedgerTransactions: ManualTransaction[] = [];

    for (let i = 0; i < this._unreconciledLedgerTransactions.length; i += 1) {
      const ledgerTransaction = this._unreconciledLedgerTransactions[i];
      const ledgerTransactionFiltered = this.unreconciledUnselectedLedgerTransactionsFiltered.find(
        (transaction) => transaction.rowId === ledgerTransaction.rowId
      );
      if (ledgerTransactionFiltered) {
        unreconciledLedgerTransactions.push({ ...ledgerTransaction, selected: true });
      } else {
        unreconciledLedgerTransactions.push(ledgerTransaction);
      }
    }
    this._unreconciledLedgerTransactions = unreconciledLedgerTransactions;
  };

  @action
  removeSelectedBankUnreconciledManualTransactions = (): void => {
    for (let i = 0; i < this._unreconciledBankTransactions.length; i += 1) {
      const bankTransaction = this._unreconciledBankTransactions[i];
      if (bankTransaction.selected) {
        bankTransaction.selected = false;
      }
    }
  };

  @action
  removeSelectedLedgerUnreconciledManualTransactions = (): void => {
    for (let i = 0; i < this._unreconciledLedgerTransactions.length; i += 1) {
      const ledgerTransaction = this._unreconciledLedgerTransactions[i];
      if (ledgerTransaction.selected) {
        ledgerTransaction.selected = false;
      }
    }
  };

  @action
  setLedgerTransactionDate = (date: string): void => {
    this._draftLedgerTransaction.date = date;
  };

  get ledgerTransactionDate(): string {
    return this._draftLedgerTransaction.date;
  }

  @action
  setLedgerTransactionDescription = (description: string): void => {
    this._draftLedgerTransaction.description = description;
  };

  get ledgerTransactionDescription(): string {
    return this._draftLedgerTransaction.description;
  }

  @action
  setLedgerTransactionReference = (reference: string): void => {
    this._draftLedgerTransaction.reference = reference;
  };

  get ledgerTransactionReference(): string {
    return this._draftLedgerTransaction.reference || '';
  }

  @action
  setLedgerTransactionAmount = (amount: string): void => {
    console.log('setLedgerTransactionAmount', amount);
    this._draftLedgerTransaction.amount = amount;
  };

  get ledgerTransactionAmount(): string {
    return this._draftLedgerTransaction.amount;
  }

  @action
  setLedgerTransactionType = (type: string): void => {
    this._draftLedgerTransaction.transactionType = type;
  };

  get ledgerTransactionType(): string {
    return this._draftLedgerTransaction.transactionType;
  }

  @action
  setLedgerTransactionFinancialTransactionId = (id: string): void => {
    this._draftLedgerTransaction.financialTransactionId = id;
  };

  get ledgerTransactionFinancialTransactionId(): string {
    return this._draftLedgerTransaction.financialTransactionId || '';
  }

  static initializeManualTransactionParams = (): ManualLedgerTransactionParams => {
    const manualLedgerTransactionParams: ManualLedgerTransactionParams = {
      financialTransactionId: '',
      description: '',
      reference: '',
      date: INVALID_DATE,
      transactionType: '',
      amount: '',
      rowId: 0,
    };
    return manualLedgerTransactionParams;
  };

  static initializeFilterOptions = (): FilterOptions => ({
    search: '',
    date: {
      from: INVALID_DATE,
      to: INVALID_DATE,
    },
    amount: {
      from: null,
      to: null,
    },
  });

  createLedgerTransaction = async (): Promise<void> => {
    if (this.reconciliationReportId) {
      const createLedgerTransactionParams: CreateLedgerTransactionParams = {
        date: this._draftLedgerTransaction.date,
        description: this._draftLedgerTransaction.description,
        reference: this._draftLedgerTransaction.reference,
        amount: +this._draftLedgerTransaction.amount,
        transactionType: this._draftLedgerTransaction.transactionType,
        financialTransactionId: this._draftLedgerTransaction.financialTransactionId,
        reconciliationReportId: this.reconciliationReportId,
      };
      if (!this.areLedgerTransactionRequiredFieldsSet) {
        throw new Error('Ledger Transaction required fields are not set');
      }
      this.ledgerTransactionCreationStatus = AsyncStatus.PENDING;
      await this.reconciliationReportRepository.createLedgerTransaction(
        createLedgerTransactionParams
      );
    }
  };

  @action
  private createLedgerTransactionHandler = (
    stateResponse: State<number, CreateLedgerTransactionError>
  ) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      this.ledgerTransactionCreationStatus = AsyncStatus.SUCCESS;
      this.hideNewLedgerTransactionDialog();
      const ledgerTransactionRowId = stateResponse.data;
      const newLedgerTransaction = this.mapLedgerTransactionToManualTransaction(
        this._draftLedgerTransaction,
        ledgerTransactionRowId
      );
      console.log('newLedgerTransaction', newLedgerTransaction);
      this.unreconciledLedgerTransactions.push(newLedgerTransaction);

      this._draftLedgerTransaction =
        ReconciliationReportViewModel.initializeManualTransactionParams();
    }
  };

  @action
  private createLedgerTransactionsHandler = (
    stateResponse: State<number[], CreateLedgerTransactionsError>
  ) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      const ledgerTransactionRowIds = stateResponse.data;
      for (let i = 0; i < this._autoGeneratedLedgerTransactions.length; i += 1) {
        const ledgerTransaction = this._autoGeneratedLedgerTransactions[i];
        const ledgerTransactionRowId = ledgerTransactionRowIds[i];
        const manualLedgerTransaction = this.mapLedgerTransactionToManualTransaction(
          ledgerTransaction,
          ledgerTransactionRowId
        );
        this.unreconciledLedgerTransactions.push(manualLedgerTransaction);
      }

      this.autoGeneratedLedgerTransactions = [];
    }
  };

  @action
  private documentUploadedHandler = (stateResponse: State<DocumentUploadedPayload, BaseError>) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      const { openingBalance, type, isOpeningBalanceSet } = stateResponse.data;
      if (type === DOCUMENT_TYPE.BANK_STATEMENT && isOpeningBalanceSet) {
        this._bankOpeningBalance = openingBalance;
      } else if (type === DOCUMENT_TYPE.LEDGER && isOpeningBalanceSet) {
        this._ledgerOpeningBalance = openingBalance;
      }
    }
  };

  @action
  private bankOBUploadStartedHandler = (_stateResponse: State<null, BaseError>) => {
    this._bankOpeningBalance = null;
  };

  @action
  private ledgerOBUploadStartedHandler = (_stateResponse: State<null, BaseError>) => {
    this._ledgerOpeningBalance = null;
  };

  private mapLedgerTransactionToManualTransaction = (
    ledgerTransaction: CreateLedgerTransaction | ManualLedgerTransactionParams,
    ledgerTransactionRowId: number
  ): ManualTransaction => {
    const manualTransaction: ManualTransaction = {
      rowId: ledgerTransactionRowId,
      date: DateUtils.FormatYearToYY(ledgerTransaction.date),
      description: ledgerTransaction.description,
      reference: ledgerTransaction.reference || '',
      debit: '0.00',
      credit: '0.00',
      debitNumber: 0,
      creditNumber: 0,
      status: ReconciliationTransactionStatuses.Unreconciled,
      selected: true,
      financialTransactionId: ledgerTransaction.financialTransactionId || '',
    };
    const ledgerTransactionAmount = +ledgerTransaction.amount;
    if (ledgerTransaction.transactionType === AMOUNT_TYPE.DEBIT) {
      manualTransaction.debit = this.fixToThousandSeparator(ledgerTransactionAmount);
      manualTransaction.debitNumber = ledgerTransactionAmount;
    } else {
      manualTransaction.credit = this.fixToThousandSeparator(ledgerTransactionAmount);
      manualTransaction.creditNumber = ledgerTransactionAmount;
    }
    return manualTransaction;
  };

  @action
  setReconciliationPeriodStartDate = (date: string): void => {
    this._reconciliationReportPeriod.startDate = date;
  };

  @action
  setReconciliationPeriodEndDate = (date: string): void => {
    this._reconciliationReportPeriod.endDate = date;
  };

  @action
  setBankFilterFromDate = (date: string): void => {
    this._bankFilterOptions.date.from = date;
  };

  @action
  setBankFilterToDate = (date: string): void => {
    this._bankFilterOptions.date.to = date;
  };

  @action
  setLedgerFilterFromDate = (date: string): void => {
    this._ledgerFilterOptions.date.from = date;
  };

  @action
  setLedgerFilterToDate = (date: string): void => {
    this._ledgerFilterOptions.date.to = date;
  };

  handleDownloadXLSXButtonClick = () => {
    const report = this._reconciliationReport.data;
    if (report) {
      const workbook = ReconciliationReportExcelFile.reportToExcelData(
        report,
        this._reconciliationAccount.data,
        this.bankStatementTransactionsPercentage!
      );
      const fileName = `Report-${report.id}.xlsx`;
      writeFileXLSX(workbook, fileName);
    }
  };

  clear = () => {};
}

const ReconciliationReportViewModelContext = createContext<{
  viewModel: ReconciliationReportViewModel | null;
  manager: {
    setViewModel: () => void;
    clearViewModel: () => void;
    resetViewModel: () => void;
  };
} | null>(null);

interface ReconciliationReportViewModelProviderProps {
  children: React.ReactNode;
}

const ReconciliationReportViewModelProvider: React.FC<
  ReconciliationReportViewModelProviderProps
> = ({ children }: ReconciliationReportViewModelProviderProps) => {
  const reconciliationReportRepository = useReconciliationReportRepository();
  // const reconciliationReportViewModel = new ReconciliationReportViewModel(
  //   reconciliationReportRepository
  // );reconciliationReportViewModel

  const [reconciliationReportViewModel, setReconciliationReportViewModel] =
    useState<ReconciliationReportViewModel | null>(
      new ReconciliationReportViewModel(reconciliationReportRepository)
    );

  const setViewModel = () => {
    setReconciliationReportViewModel(
      new ReconciliationReportViewModel(reconciliationReportRepository)
    );
  };

  const clearViewModel = () => {
    reconciliationReportViewModel?.clear();
    setReconciliationReportViewModel(null);
  };

  const resetViewModel = () => {
    clearViewModel();
    setViewModel();
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const reconciliationReportViewModelBundle = {
    viewModel: reconciliationReportViewModel,
    manager: {
      setViewModel,
      clearViewModel,
      resetViewModel,
    },
  };
  return (
    <ReconciliationReportViewModelContext.Provider value={reconciliationReportViewModelBundle}>
      {children}
    </ReconciliationReportViewModelContext.Provider>
  );
};

const useReconciliationReportViewModel = () => {
  const viewModelBundle = useContext(ReconciliationReportViewModelContext);
  if (!viewModelBundle) throw new Error('No ReconciliationReportViewModel provided');
  return viewModelBundle.viewModel;
};

const useReconciliationReportViewModelManager = () => {
  const viewModelBundle = useContext(ReconciliationReportViewModelContext);
  if (!viewModelBundle) throw new Error('No ReconciliationReportViewModel provided');
  return viewModelBundle.manager;
};

export {
  ReconciliationReportViewModel,
  ReconciliationReportViewModelProvider,
  ReconciliationReportViewModelContext,
  useReconciliationReportViewModel,
  useReconciliationReportViewModelManager,
};
